import type { Nullable, TFSEntry } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { shallowReactive, type InjectionKey } from "vue"
import { useService } from "vue-utils"
import { canEditAnyTFSField } from "../permissions"
import { uploadTFSAdditionalDocuments, type CreateTFSData } from "../requests"
import { useTFSEntriesStore } from "../store"
import { useTFSValidation } from "./validation"

export type TFSEntryType = "create" | "edit"
export type TFSFormData = CreateTFSData

export enum TFSFormSection {
	Main = "Main",
	ConsentIssued = "ConsentIssued",
	AdditionalDocuments = "AdditionalDocuments",
}

export const TFSFormContextInjectionKey = Symbol("TFSFormContext") as InjectionKey<TFSFormContext>

export function useTFSFormContext(): TFSFormContext {
	return useService(TFSFormContextInjectionKey)
}

export class TFSFormContext {
	private readonly loggedInUser = useLoggedInUser()
	private tfsStore = useTFSEntriesStore()

	readonly formData: TFSFormData
	readonly validation

	private data = shallowReactive({
		section: TFSFormSection.Main,
		showingClosePopup: false,
	})

	constructor(public readonly existingTFS: Nullable<TFSEntry>) {
		this.formData = shallowReactive(createFormData(existingTFS))
		this.validation = useTFSValidation(this)
	}

	get type(): TFSEntryType {
		return this.existingTFS === null ? "create" : "edit"
	}
	get section(): TFSFormSection {
		return this.data.section
	}
	set section(section: TFSFormSection) {
		this.data.section = section
	}

	get showingClosePopup(): boolean {
		return this.data.showingClosePopup
	}
	set showingClosePopup(show: boolean) {
		this.data.showingClosePopup = show
	}

	get canEdit(): boolean {
		return canEditAnyTFSField(this.existingTFS, this.loggedInUser)
	}

	changeSection(newSection: TFSFormSection) {
		this.data.section = newSection
	}

	cancel() {
		return this.tfsStore.closeTFSPopup()
	}

	async save() {
		if (this.existingTFS) {
			await this.tfsStore.updateTFS(this.existingTFS.id, this.formData)
		} else {
			await this.tfsStore.createTFS(this.formData)
		}
	}

	async delete() {
		if (!this.existingTFS) {
			throw new Error("Cannot delete a newly creating TFS")
		}
		await this.tfsStore.deleteTFS(this.existingTFS.id)
	}

	async closeTFS(closingDocuments: File[]) {
		if (!this.existingTFS) {
			throw new Error("Cannot close a newly creating TFS")
		}
		await this.tfsStore.closeTFS(this.existingTFS.id, closingDocuments)
	}

	async uploadAdditionalDocuments(documents: File[]) {
		if (!this.existingTFS) {
			throw new Error("Cannot upload additional documents to a newly creating TFS")
		}

		const tfs = this.existingTFS
		const newDocs = await uploadTFSAdditionalDocuments(tfs.id, documents)

		tfs.additionalDocuments ??= []
		tfs.additionalDocuments.push(...newDocs)

		return newDocs
	}
}

function createFormData(tfs: Nullable<TFSEntry>): TFSFormData {
	if (tfs) {
		return {
			...tfs,
			consentIssued: tfs.consentIssued !== null,
			consentIssuedDocuments: [],
		}
	}
	return {
		tfsNumber: "",
		wkNumber: "",
		companyId: 0,
		validFrom: null,
		validTo: null,
		maximumShipments: null,
		maximumActiveShipments: null,
		consentIssued: false,
		consentIssuedDocuments: [],
		notes: "",
	}
}
