import type { Id, ShipmentLog, ShipmentLogDocumentKeys, TFSEntryMetadata } from "@/models"
import { inject, provide, type InjectionKey } from "vue"
import { type Loadable } from "vue-utils"
import {
	cancelShipment,
	completeShipment,
	createShipment,
	getNextShipmentNumber,
	uploadShipmentDocuments,
	type SearchShipmentsResult,
} from "./requests"
import { useSearchShipments } from "./useSearchShipments"

/* 
	Shipment store is defined using Vue's dependency injection (rather than via Pinia like other stores)
	due to the data being dependant on the visited page URL
*/

const injectionKey = Symbol("shipments-store") as InjectionKey<ShipmentsStore>

export function useShipmentsStore(): ShipmentsStore {
	const store = inject(injectionKey)
	if (!store) {
		throw new Error("Shipments store not defined")
	}
	return store
}

export function setupShipmentsStore(tfs: TFSEntryMetadata): ShipmentsStore {
	const store = new ShipmentsStore(tfs)
	provide(injectionKey, store)

	return store
}

class ShipmentsStore {
	private readonly loadedTfs: TFSEntryMetadata
	private readonly search

	constructor(loadedTfs: TFSEntryMetadata) {
		this.loadedTfs = loadedTfs
		this.search = useSearchShipments(loadedTfs.id)
	}

	/**
      The TFS metadata also comes through from the shipment log table.

      When the table is refreshed (i.e. after creating documents or a new shipment log), the displayed tfs metadata
      also will likely need to update. 
   */
	get tfs(): TFSEntryMetadata {
		if ("result" in this.shipmentsRef && this.shipmentsRef.result) {
			return this.shipmentsRef.result.tfsMetadata
		}
		return this.loadedTfs
	}

	get requestOptions() {
		return this.search.requestOptions
	}

	get shipmentsRef(): Loadable<SearchShipmentsResult> {
		return this.search.loadableRef
	}

	async refreshResults(): Promise<void> {
		await this.search.refreshResults()
	}

	resetSearchOptions(): void {
		this.search.resetOptions()
	}

	hasResults(): boolean {
		return this.search.hasResults()
	}

	getResults(): SearchShipmentsResult {
		return this.search.getResults()
	}

	async createShipment(shipmentNumber: number, files: File[]): Promise<ShipmentLog> {
		const shipment = await createShipment(this.tfs.id, shipmentNumber, files)
		void this.refreshResults()
		return shipment
	}

	async uploadDocuments(shipment: Id | ShipmentLog, files: File[], type: ShipmentLogDocumentKeys) {
		const shipmentId = typeof shipment === "number" ? shipment : shipment.id
		await uploadShipmentDocuments(shipmentId, files, type)
		void this.refreshResults()
	}

	async cancelShipment(shipment: Id | ShipmentLog) {
		const shipmentId = typeof shipment === "number" ? shipment : shipment.id
		await cancelShipment(shipmentId)
		void this.refreshResults()
	}

	async completeShipment(shipment: Id | ShipmentLog) {
		const shipmentId = typeof shipment === "number" ? shipment : shipment.id
		await completeShipment(shipmentId)
		void this.refreshResults()
	}

	async getNextShipmentNumber(tfs: Id | TFSEntryMetadata) {
		const tfsId = typeof tfs === "number" ? tfs : tfs.id
		return await getNextShipmentNumber(tfsId)
	}
}
