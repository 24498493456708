import { SortDirection } from "@/models"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import type { ThHTMLAttributes } from "vue"
import { computed, renderSlot } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"

interface Props {
	direction: SortDirection | null
	setDirection(direction: SortDirection): void
	defaultDirection?: SortDirection
}

const headerStyles = css`
	padding: 0 !important;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: stretch !important;
	align-items: stretch !important;

	button {
		user-select: none;
		all: unset;

		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1;

		padding: 0.35em 0.5em;

		cursor: pointer;
		&:hover {
			background-color: #b1dbb5ae;
		}

		& > svg {
			width: 1.5rem;
		}
	}
	span {
		user-select: none;
	}

	svg {
		font-size: 0.85em;
	}
`

const SortableHeading: ReactiveComponent<Props, ThHTMLAttributes> = (props, { slots, attrs }) => {
	function toggle() {
		if (props.direction === SortDirection.Ascending) {
			props.setDirection(SortDirection.Descending)
		} else if (props.direction === SortDirection.Descending) {
			props.setDirection(SortDirection.Ascending)
		} else {
			props.setDirection(props.defaultDirection ?? SortDirection.Descending)
		}
	}
	const justify = computed(() => {
		switch (attrs.align) {
			case "center":
				return "center"
			case "left":
				return "space-between"
			case "right":
				return "flex-end"
		}
		return undefined
	})

	return () => (
		<th class={headerStyles} {...attrs}>
			<button onClick={toggle} style={{ justifyContent: justify.value }}>
				<span>{renderSlot(slots, "default")}</span>
				{!!props.direction && <Icon icon={props.direction === SortDirection.Ascending ? faChevronUp : faChevronDown} />}
			</button>
		</th>
	)
}

export default defineComponent(SortableHeading, {
	direction: requiredProp(String, null),
	defaultDirection: optionalProp(String),
	setDirection: requiredProp(Function),
})
