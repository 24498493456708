import { Duration } from "@js-joda/core"
import { RouterView } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { StateLoading } from "./components/loading/StateLoading"
import { useStoreRefresh } from "./composition/useStoreRefresh"
import { useCompaniesStore } from "./stores/companiesStore"

const MainApp: ReactiveComponent = () => {
	const companiesStore = useCompaniesStore()
	useStoreRefresh({ companies: companiesStore }, Duration.ofHours(12))

	return () => (
		<StateLoading stores={[companiesStore]}>
			<RouterView />
		</StateLoading>
	)
}

export default defineComponent(MainApp)
