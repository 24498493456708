import { WasteShippingRoutes } from "@/pages/navigation"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import AppName from "./AppName"
import Navigation from "./Navigation"

interface Props {
	title: string
}

const navStyles = css`
	grid-row-start: span 2;
	background-color: white;
	width: 17rem;
	z-index: 3;

	color: white;
	background-color: #1b1f2b;
	border-right: thin solid rgba(0, 0, 0, 0.5);
	box-shadow: 0.25rem 0 0.25rem rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;

	@media (max-width: 96rem) {
		width: auto;
	}
`

const NavPanel: ReactiveComponent<Props> = () => {
	return () => (
		<nav class={navStyles}>
			<AppName />
			<Navigation routes={WasteShippingRoutes} />
		</nav>
	)
}

export default defineComponent(NavPanel, {
	title: requiredProp(String),
})
