import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import { showErrorMessage } from "@/composition/useLoading"
import type { DocumentUpload } from "@/models"
import { generateDownloadLink } from "@/utils/downloadFile"
import { getFileIcon } from "@/utils/fileIcons"
import { proxyProp } from "@/utils/proxyProp"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import { faDownload, faUser } from "@fortawesome/free-solid-svg-icons"
import { ZoneId } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { ref, toRef } from "vue"
import { defineComponent, formatFileSize, requiredProp, runWithMinimumDelay, type ReactiveComponent } from "vue-utils"

interface Props {
	document: DocumentUpload
}

const rowStyles = css`
	td {
		padding: 0.35rem 0.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);

		display: flex;
		column-gap: 0.25rem;
		align-items: center;

		font-size: 1rem;

		svg {
			color: #333;
		}
	}
	td:first-child {
		padding-left: 0.25rem;
		column-gap: 0.5rem;
		svg {
			font-size: 1.25rem;
		}
		span {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	td:nth-child(3) svg {
		color: #0e406e;
	}
	td:nth-child(4) {
		text-align: right;
		justify-content: end;
	}
	td:last-child {
		padding-right: 0;
		svg {
			color: white;
		}
	}
`

const ExistingDocumentRow: ReactiveComponent<Props> = (props) => {
	const document = proxyProp(props, "document")
	const uploadedBy = toRef(() => document.uploadedBy.user)
	const uploadedDate = toRef(() => document.uploadedBy.timeStamp.atZone(ZoneId.SYSTEM).toLocalDate())

	const downloading = ref(false)

	async function download() {
		if (downloading.value) {
			return
		}
		downloading.value = true
		try {
			const downloadLink = await runWithMinimumDelay(generateDownloadLink(props.document.guid), 750)
			window.open(downloadLink, "_blank", "noopener noreferrer")
		} catch (e) {
			void showErrorMessage("Failed to download file", (e as Error).message)
		} finally {
			downloading.value = false
		}
	}

	return () => (
		<tr key={document.guid} class={rowStyles}>
			<td>
				<Icon icon={getFileIcon(document.mimeType)} />
				<span title={document.fileName}>{document.fileName}</span>
			</td>
			<td title="Date Uploaded">
				<Icon icon={faCalendar} />
				<span>{uploadedDate.value.toGBDateString()}</span>
			</td>
			<td title="Uploaded By">
				<Icon icon={faUser} />
				<span>{uploadedBy.value ? `${uploadedBy.value.firstName} ${uploadedBy.value.lastName}` : "Unknown"}</span>
			</td>
			<td>{formatFileSize(document.fileSize)}</td>
			<td>
				<BootstrapButton
					color="success"
					title="Download File"
					isSubmitting={downloading.value}
					disabled={downloading.value}
					onClick={() => void download()}
				>
					{!downloading.value && <Icon icon={faDownload} />}
				</BootstrapButton>
			</td>
		</tr>
	)
}

export default defineComponent(ExistingDocumentRow, {
	document: requiredProp(Object),
})
