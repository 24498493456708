/* eslint-disable vue/one-component-per-file */
import Icon from "@/components/Icon"
import AnchorButton from "@/components/form/AnchorButton"
import BootstrapButton from "@/components/form/BootstrapButton"
import { SystemRole, getTFSStatusColor, type TFSEntryMetadata } from "@/models"
import { useAtLeastHasRole, useLoggedInUser } from "@/stores/loggedInUserStore"
import { faEdit, faEye, faShip } from "@fortawesome/free-solid-svg-icons"
import { ZoneId } from "@js-joda/core"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { canEditAnyTFSField } from "../permissions"
import { TFSSearchSorting } from "../requests"
import { useTFSEntriesStore } from "../store"
import SortableTFSHeading from "./SortableTFSHeading"
import "./tableStyles.scss"

const TFSTable: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()
	const isAdmin = useAtLeastHasRole(SystemRole.Administrator)

	const store = useTFSEntriesStore()

	function canOpenTFS(tfs: TFSEntryMetadata): boolean {
		return tfs.totalShipments > 0 || !!tfs.consentIssued
	}

	return () => (
		<table class="grid-table tfs-entries-table" data-no-company={String(!isAdmin.value)}>
			<thead>
				<tr>
					<SortableTFSHeading sort={TFSSearchSorting.TFSNumber} text="TFS No" rowspan={2} align="left" />
					<SortableTFSHeading sort={TFSSearchSorting.WKNumber} text="WK No" rowspan={2} align="left" />
					<SortableTFSHeading sort={TFSSearchSorting.Status} text="Status" rowspan={2} align="left" />
					{isAdmin.value && (
						<SortableTFSHeading sort={TFSSearchSorting.CompanyName} text="Company" rowspan={2} align="left" />
					)}
					{isAdmin.value && (
						<SortableTFSHeading sort={TFSSearchSorting.ConsentIssued} text="Consent Issued?" rowspan={2} />
					)}
					<SortableTFSHeading sort={TFSSearchSorting.ValidFrom} text="Valid From" rowspan={2} />
					<SortableTFSHeading sort={TFSSearchSorting.ValidTo} text="Valid To" rowspan={2} />
					<th colspan={2}>Maximum Shipments</th>
					<th colspan={4}>Associated Shipment Counts</th>
					<th rowspan={2} style={{ border: "none", backgroundColor: "transparent", padding: 0 }}></th>
				</tr>
				<tr>
					<SortableTFSHeading sort={TFSSearchSorting.MaximumShipments} text="Total" />
					<SortableTFSHeading sort={TFSSearchSorting.MaximumActiveShipments} text="Active" />

					<SortableTFSHeading sort={TFSSearchSorting.CompletedShipments} text="Complete" />
					<SortableTFSHeading sort={TFSSearchSorting.CancelledShipments} text="Cancelled" />
					<SortableTFSHeading sort={TFSSearchSorting.ActiveShipments} text="Active" />
					<SortableTFSHeading sort={TFSSearchSorting.TotalShipments} text="Total/Used" />
				</tr>
			</thead>
			<tbody>
				{store.getResults().entries.map((tfs) => (
					<tr key={tfs.id} style={{ "--tfs-row-color": getTFSStatusColor(tfs.status) }}>
						<td data-left="true">{tfs.tfsNumber}</td>
						<td data-left="true">{tfs.wkNumber}</td>
						<td data-left="true">{tfs.status}</td>
						{isAdmin.value && <td data-left="true">{tfs.companyName}</td>}
						{isAdmin.value && (
							<td>
								{tfs.consentIssued ? tfs.consentIssued.atZone(ZoneId.SYSTEM).toLocalDate().toGBDateString() : "No"}
							</td>
						)}
						<td>{tfs.validFrom?.toGBDateString() ?? "-"}</td>
						<td>{tfs.validTo?.toGBDateString() ?? "-"}</td>
						<td>{tfs.maximumShipments ?? "-"}</td>
						<td>{tfs.maximumActiveShipments ?? "-"}</td>
						<td>{tfs.completedShipments}</td>
						<td>{tfs.cancelledShipments}</td>
						<td>{tfs.activeShipments}</td>
						<td>{tfs.totalShipments}</td>
						<td
							class="spacing"
							style={{ border: "none", backgroundColor: "transparent" }}
							onClick={(e) => e.stopPropagation()}
						>
							<BootstrapButton
								class="flex-grow-1"
								color="secondary"
								onClick={() => store.openTFS(tfs.id)}
								title={canEditAnyTFSField(tfs, loggedInUser) ? "Edit TFS" : "View TFS"}
							>
								<Icon icon={canEditAnyTFSField(tfs, loggedInUser) ? faEdit : faEye} />
							</BootstrapButton>
							{canOpenTFS(tfs) && (
								<AnchorButton
									class="flex-grow-1"
									color="primary"
									href={`/tfs-entries/${tfs.id}`}
									title="Click to View Shipping Information"
								>
									<Icon icon={faShip} />
								</AnchorButton>
							)}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default defineComponent(TFSTable)
