import { usePagedFilteredSearching } from "@/composition/usePagedFilteredSearching"
import { ShipmentStatus, SortDirection, type Id } from "@/models"
import { toReactive } from "@vueuse/core"
import { toRef } from "vue"
import { RefQueries, useQueryStoreRef } from "vue-utils"
import { SearchShipmentsSortBy, searchShipments, type SearchShipmentsFilter } from "./requests"

export interface ShipmentsRequestOptions {
	tfsId: Id
	page: number
	pageSize: number
	sortBy: SearchShipmentsSortBy
	sortDirection: SortDirection
	filter: SearchShipmentsFilter
}

export function useSearchShipments(tfsId: Id) {
	return usePagedFilteredSearching({
		getDefault: (): ShipmentsRequestOptions => ({
			tfsId,
			page: 1,
			pageSize: 10,
			sortBy: SearchShipmentsSortBy.ShipmentNumber,
			sortDirection: SortDirection.Descending,
			filter: {
				statuses: [],
				hasRecoveryAtFacility: null,
				hasAdditionalDocuments: null,
			},
		}),
		loadValue: searchShipments,
	})
}

export function bindShipmentsRequestToQuery(request: ShipmentsRequestOptions) {
	useQueryStoreRef("page", request, "page", RefQueries.Int)
	useQueryStoreRef("page-size", request, "pageSize", RefQueries.Int)
	useQueryStoreRef("by", request, "sortBy", RefQueries.enum(SearchShipmentsSortBy))
	useQueryStoreRef("sort", request, "sortDirection", RefQueries.enum(SortDirection))

	const filterRef = toReactive(toRef(request, "filter"))
	bindShipmentsFilterToQuery(filterRef)
}

export function bindShipmentsFilterToQuery(filter: SearchShipmentsFilter) {
	useQueryStoreRef("status", filter, "statuses", RefQueries.arrayOf(RefQueries.enum(ShipmentStatus)))
	useQueryStoreRef("recovery-at-facility", filter, "hasRecoveryAtFacility", RefQueries.NullableBoolean)
	useQueryStoreRef("additional-docs", filter, "hasAdditionalDocuments", RefQueries.NullableBoolean)
}
