import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import type { ShipmentLog, ShipmentLogDocumentKeys } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { formatEnum } from "@/utils/formatEnum"
import { faEdit, faEye, faUpload } from "@fortawesome/free-solid-svg-icons"
import { Teleport, computed, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import ManageDocuments from "../documents/ManageDocuments"
import { canEditDocuments } from "../permissions"
import { useShipmentsStore } from "../store"

interface Props {
	shipment: ShipmentLog
	type: ShipmentLogDocumentKeys
}

const DocumentsColumn: ReactiveComponent<Props> = (props) => {
	const store = useShipmentsStore()
	const loggedInUser = useLoggedInUser()
	const showingDocs = ref(false)
	const canUpload = computed(() => canEditDocuments({ loggedInUser, tfs: store.tfs, ...props }))

	function renderDocumentCount() {
		const documents = props.shipment[props.type]
		if (!documents || documents.length === 0) {
			return <i>No documents uploaded</i>
		}
		if (documents.length === 1) {
			return "1 Document"
		}
		return `${documents.length} Documents`
	}

	async function handleUpload(files: File[]) {
		await store.uploadDocuments(props.shipment, files, props.type)
		showingDocs.value = false
	}

	function renderButtons() {
		const documents = props.shipment[props.type]
		const hasDocuments = !!documents && documents.length > 0
		const canEdit = canUpload.value

		if (canEdit && !hasDocuments) {
			return (
				<BootstrapButton color="success" title="Upload Documents" onClick={() => (showingDocs.value = true)}>
					<Icon icon={faUpload} />
				</BootstrapButton>
			)
		}
		if (canEdit && hasDocuments) {
			return (
				<BootstrapButton color="primary" title="Edit & View Documents" onClick={() => (showingDocs.value = true)}>
					<Icon icon={faEdit} />
				</BootstrapButton>
			)
		}
		if (!canEdit && hasDocuments) {
			return (
				<BootstrapButton color="secondary" title="View Documents" onClick={() => (showingDocs.value = true)}>
					<Icon icon={faEye} />
				</BootstrapButton>
			)
		}

		return null
	}

	return () => (
		<td
			class="flex flex-wrap h-full justify-between spacing-2"
			style={canUpload.value ? { textDecoration: "none" } : ""}
		>
			<span>{renderDocumentCount()}</span>
			<div class="inline-flex items-center spacing-2">{renderButtons()}</div>

			{showingDocs.value && (
				<Teleport to="body">
					<ManageDocuments
						close={() => (showingDocs.value = false)}
						title={`${formatEnum(props.type)} (Shipment ${props.shipment.shipmentNumber})`}
						canUpload={canUpload.value}
						existingDocuments={props.shipment[props.type] ?? []}
						uploadDocuments={handleUpload}
						type={props.type}
					/>
				</Teleport>
			)}
		</td>
	)
}

export default defineComponent(DocumentsColumn, {
	shipment: requiredProp(Object),
	type: requiredProp(String),
})
