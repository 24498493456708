import { getFileIcon } from "@/utils/fileIcons"
import { faDownload, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import {
	defineComponent,
	downloadFile,
	formatFileSize,
	optionalProp,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"
import Icon from "../Icon"
import PlainIconButton from "../PlainIconButton"

interface Props {
	file: File
	remove?(): void
}

const indicatorStyles = css`
	display: grid;
	grid-template-columns: 1em 1fr max-content 1.5em 1.5em;
	align-items: center;
	column-gap: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.35);
	padding: 0.35rem 0.25rem;

	span {
		font-size: 1rem;
	}

	& > span:nth-child(2) {
		flex: 1 1 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	svg {
		margin: 0;
		font-size: 1.25rem;
	}
	& > button:nth-child(5) {
		color: var(--color-error);
	}
	& > button:nth-child(4) {
		color: var(--bs-dark);
	}
`

const FileIndicator: ReactiveComponent<Props> = (props) => {
	async function download() {
		const content = await props.file.arrayBuffer()
		await downloadFile(new Blob([content]), props.file.name)
	}

	return () => (
		<div class={indicatorStyles}>
			<Icon icon={getFileIcon(props.file.type)} />
			<span title={props.file.name}>{props.file.name}</span>
			<span>{formatFileSize(props.file.size)}</span>
			<PlainIconButton icon={faDownload} title="Download File" onClick={() => void download()} />
			{!!props.remove && <PlainIconButton icon={faTimesCircle} title="Remove File" onClick={props.remove} />}
		</div>
	)
}

export default defineComponent(FileIndicator, {
	file: requiredProp(File),
	remove: optionalProp(Function),
})
