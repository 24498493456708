/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import type { Company, Id } from "."
import { SystemRole, getRolePriority, type User } from "./User"

export interface LoggedInUser extends User {}
export class LoggedInUser implements User {
	constructor(user: User) {
		Object.assign(this, user)
	}

	atLeastHasRole(role: SystemRole): boolean {
		const ownPriority = getRolePriority(this.role)
		const rolePriority = getRolePriority(role)

		return ownPriority >= rolePriority
	}

	isAssignedToCompany(company: Id | Company): boolean {
		const companyId = typeof company === "number" ? company : company.id
		return companyId === this.companyId
	}

	canViewCompany(company: Id | Company): boolean {
		return this.atLeastHasRole(SystemRole.Administrator) || this.isAssignedToCompany(company)
	}
}
