import { Instant, LocalDate } from "@js-joda/core"
import type { ApiTypeOf } from "./ApiType"
import { mapApiDocumentUpload, type DocumentUpload } from "./DocumentUpload"
import type { GenericEntity, Id, Nullable } from "./GenericEntity"
import type { TFSStatus } from "./TFSStatus"
import { mapApiUserTimeStamp, type UserTimeStamp } from "./UserTimeStamp"

export interface CommonTFSData extends GenericEntity {
	tfsNumber: string
	wkNumber: string
	validFrom: Nullable<LocalDate>
	validTo: Nullable<LocalDate>

	companyId: Id

	notes: string

	maximumShipments: Nullable<number>
	maximumActiveShipments: Nullable<number>
}

export interface TFSEntry extends CommonTFSData {
	createdBy: UserTimeStamp
	consentIssued: Nullable<UserTimeStamp>
	closed: Nullable<UserTimeStamp>

	closedDocuments: Nullable<DocumentUpload[]>
	consentIssuedDocuments: Nullable<DocumentUpload[]>
	additionalDocuments: Nullable<DocumentUpload[]>
}

export function mapApiTFSEntry(entry: ApiTypeOf<TFSEntry>): TFSEntry {
	return {
		...entry,
		validFrom: entry.validFrom ? LocalDate.parse(entry.validFrom) : null,
		validTo: entry.validTo ? LocalDate.parse(entry.validTo) : null,
		createdBy: mapApiUserTimeStamp(entry.createdBy),
		consentIssued: entry.consentIssued && mapApiUserTimeStamp(entry.consentIssued),
		closed: entry.closed && mapApiUserTimeStamp(entry.closed),
		closedDocuments: entry.closedDocuments?.map(mapApiDocumentUpload) ?? null,
		consentIssuedDocuments: entry.consentIssuedDocuments?.map(mapApiDocumentUpload) ?? null,
		additionalDocuments: entry.additionalDocuments?.map(mapApiDocumentUpload) ?? null,
	}
}

export interface TFSEntryMetadata extends CommonTFSData {
	companyName: string

	status: TFSStatus

	created: Instant
	consentIssued: Nullable<Instant>
	closed: Nullable<Instant>

	activeShipments: number
	completedShipments: number
	cancelledShipments: number
	totalShipments: number
}

export function mapApiTFSEntryMetadata(entry: ApiTypeOf<TFSEntryMetadata>): TFSEntryMetadata {
	return {
		...entry,
		validFrom: entry.validFrom ? LocalDate.parse(entry.validFrom) : null,
		validTo: entry.validTo ? LocalDate.parse(entry.validTo) : null,
		created: Instant.parse(entry.created),
		consentIssued: entry.consentIssued ? Instant.parse(entry.consentIssued) : null,
		closed: entry.closed ? Instant.parse(entry.closed) : null,
	}
}
