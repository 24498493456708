import BootstrapButton from "@/components/form/BootstrapButton"
import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import ExistingDocuments from "@/shared/ExistingDocuments"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useTFSFormContext } from "../context"
import UploadAdditionalDocs from "./UploadAdditionalDocs"

const AdditionalDocuments: ReactiveComponent = () => {
	const context = useTFSFormContext()
	const uploadingDocs = ref(false)

	return () => (
		<>
			<ExistingDocuments name="additional" documents={context.existingTFS?.additionalDocuments ?? []} />
			{context.canEdit && (
				<div class="mt-3">
					<BootstrapButton color="success" onClick={() => (uploadingDocs.value = true)}>
						<Icon icon={faPlusCircle} />
						Upload Documents
					</BootstrapButton>
				</div>
			)}
			{uploadingDocs.value && context.canEdit && (
				<Modal zIndex={4} style={{ width: "56rem", height: "32rem" }}>
					<UploadAdditionalDocs
						cancel={() => (uploadingDocs.value = false)}
						onUploaded={() => (uploadingDocs.value = false)}
					/>
				</Modal>
			)}
		</>
	)
}

export default defineComponent(AdditionalDocuments)
