import { AppAuthContext } from "@/Authentication"
import AnchorButton from "@/components/form/AnchorButton"
import Icon from "@/components/Icon"
import { useCompaniesStore } from "@/stores/companiesStore"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import LogoutButton from "./LogoutButton"

const gridStyles = css`
	* {
		text-align: left;
	}
	th {
		font-weight: 600;
		padding-right: 1rem;
	}
	td {
		font-weight: normal;
	}
`

const ProfilePage: ReactiveComponent = () => {
	const user = useLoggedInUser()
	const companiesStore = useCompaniesStore()

	const associatedCompany = computed(() => {
		if (companiesStore.type !== "done") {
			return null
		}
		if (user.companyId === null) {
			return null
		}
		return companiesStore.result.get(user.companyId) ?? null
	})

	return () => (
		<BasicPage title="Profile Information" noNav={!user.loginEnabled} scroll={false}>
			<table class={gridStyles}>
				<tbody>
					<tr>
						<th>Name:</th>
						<td>
							{user.firstName} {user.lastName}
						</td>
					</tr>
					<tr>
						<th>Email: </th>
						<td>{user.emailAddress}</td>
					</tr>
					<tr>
						<th>Role: </th>
						<td>{user.role}</td>
					</tr>
					{!!associatedCompany.value && (
						<tr>
							<th>Company</th>
							<td>{associatedCompany.value.name}</td>
						</tr>
					)}
					<tr>
						<th>Login Enabled</th>
						<td>{user.loginEnabled ? "Yes" : "No"}</td>
					</tr>
					<tr>
						<th>User Id: </th>
						<td>{user.guid}</td>
					</tr>
				</tbody>
			</table>
			<br />
			<div class="inline-flex flex-wrap spacing-4">
				<AnchorButton color="success" href={AppAuthContext.getKeycloakManageUrl()} nonRoute>
					<Icon icon={faUser} />
					<span>Manage Account</span>
				</AnchorButton>
				<LogoutButton />
			</div>
		</BasicPage>
	)
}

export default defineComponent(ProfilePage)
