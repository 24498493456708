import NavPanel from "@/components/navigation"
import AppHeader from "@/components/titlebar/AppHeader"
import { LoadingWrapper } from "@/composition/useLoading"
import { css } from "vite-css-in-js"
import { renderSlot } from "vue"
import {
	ShadowContainer,
	defineComponent,
	optionalProp,
	propsWithDefaults,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	title: string
	noPadding?: boolean
	noNav?: boolean
	plain?: boolean
	scroll?: boolean
}

const regularPageStyles = css`
	width: 100%;
	height: 100%;

	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: auto 1fr;
`

const noNavPageStyles = css`
	width: 100%;
	height: 100%;

	display: grid;
	grid-template-rows: auto 1fr;
`

const BasicPage: ReactiveComponent<Props> = (initialProps, { slots }) => {
	const props = propsWithDefaults(initialProps, {
		noPadding: false,
		noNav: false,
		plain: false,
		scroll: true,
	})

	function renderMainContent() {
		if (props.plain) {
			return renderContent()
		}
		if (props.scroll) {
			return (
				<ShadowContainer class="w-full h-full">
					<div class="vu-scroll-pane w-full h-full">
						<div style={{ padding: "0.125rem" }}>{renderContent()}</div>
					</div>
				</ShadowContainer>
			)
		}
		return <ShadowContainer class="w-full">{renderContent()}</ShadowContainer>
	}

	function renderContent() {
		return <LoadingWrapper>{renderSlot(slots, "default")}</LoadingWrapper>
	}

	return () => (
		<div class={props.noNav ? noNavPageStyles : regularPageStyles}>
			{!props.noNav && <NavPanel title={props.title} />}
			<AppHeader title={props.title} showLogo={props.noNav} />
			<main style={{ padding: props.noPadding ? undefined : "0.75rem" }}>{renderMainContent()}</main>
		</div>
	)
}

export default defineComponent(BasicPage, {
	title: requiredProp(String),
	noPadding: optionalProp(Boolean),
	noNav: optionalProp(Boolean),
	plain: optionalProp(Boolean),
	scroll: optionalProp(Boolean),
})
