import { usePagedFilteredSearching } from "@/composition/usePagedFilteredSearching"
import { SortDirection, TFSStatus, type Nullable } from "@/models"
import { toReactive } from "@vueuse/core"
import { toRef } from "vue"
import { RefQueries, useQueryStoreRef } from "vue-utils"
import { TFSSearchSorting, searchTFSMetadata, type TFSSearchFilter } from "./requests"

export interface TFSRequestOptions {
	page: number
	pageSize: number
	sortBy: Nullable<TFSSearchSorting>
	sortDirection: Nullable<SortDirection>
	filter: TFSSearchFilter
}

export function useTFSSearch() {
	const { loadableRef: tfsEntriesRef, ...rest } = usePagedFilteredSearching({
		loadValue: searchTFSMetadata,
		getDefault: (): TFSRequestOptions => ({
			page: 1,
			pageSize: 12,
			sortBy: null,
			sortDirection: null,
			filter: {
				consentIssued: null,
				statuses: [],
				companyIds: [],
				tfsNumber: null,
				wkNumber: null,
			},
		}),
	})
	return {
		tfsEntriesRef,
		...rest,
	}
}

export function bindTFSRequestToQuery(request: TFSRequestOptions) {
	useQueryStoreRef("page", request, "page", RefQueries.Int)
	useQueryStoreRef("page-size", request, "pageSize", RefQueries.Int)
	useQueryStoreRef("by", request, "sortBy", RefQueries.nullableEnum(TFSSearchSorting))
	useQueryStoreRef("sort", request, "sortDirection", RefQueries.nullableEnum(SortDirection))

	const filterRef = toReactive(toRef(request, "filter"))
	bindTFSFilterToQuery(filterRef)
}

export function bindTFSFilterToQuery(filter: TFSSearchFilter) {
	useQueryStoreRef("consent-issued", filter, "consentIssued", RefQueries.NullableBoolean)
	useQueryStoreRef("status", filter, "statuses", RefQueries.arrayOf(RefQueries.enum(TFSStatus)))
	useQueryStoreRef("company-id", filter, "companyIds", RefQueries.arrayOf(RefQueries.Int))
	useQueryStoreRef("tfs-num", filter, "tfsNumber", RefQueries.asNullable(RefQueries.String))
	useQueryStoreRef("wk", filter, "wkNumber", RefQueries.asNullable(RefQueries.String))
}
