import type { Id, TFSEntry } from "@/models"
import { defineStore } from "pinia"
import { toRef } from "vue"
import { useEditingTFS } from "./editing-tfs/useEditingTFS"
import {
	closeTFSEntry,
	createTFSEntry,
	deleteTFSEntry,
	updateTFSEntry,
	type CreateTFSData,
	type UpdateTFSData,
} from "./requests"
import { useTFSSearch } from "./useTFSSearch"

export const useTFSEntriesStore = defineStore("tfs-entries", () => {
	const tfsSearch = useTFSSearch()
	const editingTfs = useEditingTFS()

	async function createTFS(createData: CreateTFSData): Promise<void> {
		await createTFSEntry(createData)

		editingTfs.closeTFSPopup()
		void tfsSearch.refreshResults()
	}

	async function updateTFS(tfsId: Id, updateData: UpdateTFSData): Promise<void> {
		await updateTFSEntry(tfsId, updateData)

		editingTfs.closeTFSPopup()
		void tfsSearch.refreshResults()
	}

	async function closeTFS(tfs: Id | TFSEntry, files: File[]) {
		const tfsId = typeof tfs === "number" ? tfs : tfs.id
		await closeTFSEntry(tfsId, files)

		editingTfs.closeTFSPopup()
		void tfsSearch.refreshResults()
	}

	async function deleteTFS(tfs: Id | TFSEntry) {
		const tfsId = typeof tfs === "number" ? tfs : tfs.id
		await deleteTFSEntry(tfsId)

		editingTfs.closeTFSPopup()
		void tfsSearch.refreshResults()
	}

	return {
		...tfsSearch,
		...editingTfs,

		tfsEntriesRef: toRef(() => tfsSearch.tfsEntriesRef),

		createTFS,
		updateTFS,
		closeTFS,
		deleteTFS,
	}
})
