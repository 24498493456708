import {
	ShipmentStatus,
	SortDirection,
	mapApiShipmentLog,
	mapApiTFSEntryMetadata,
	type IPagedRequest,
	type IPagedResult,
	type Id,
	type Nullable,
	type ShipmentLog,
	type ShipmentLogDocumentKeys,
	type TFSEntryMetadata,
} from "@/models"
import {
	RequestMethod,
	httpJsonRequestJsonResponse,
	httpRequest,
	httpRequestJsonResponse,
} from "@/services/httpService"

export async function createShipment(
	tfsId: Id,
	shipmentNumber: number,
	notificationOfShipmentFiles: File[]
): Promise<ShipmentLog> {
	const formData = new FormData()
	formData.set("tfsId", String(tfsId))
	formData.set("shipmentNumber", String(shipmentNumber))

	for (const file of notificationOfShipmentFiles) {
		formData.append("files", file)
	}

	const log = await httpRequestJsonResponse<ShipmentLog>("/api/shipments", RequestMethod.POST, {
		body: formData,
	})

	return mapApiShipmentLog(log)
}

export async function uploadShipmentDocuments(
	shipmentId: Id,
	files: File[],
	type: ShipmentLogDocumentKeys
): Promise<ShipmentLog> {
	const formData = new FormData()
	formData.set("shipmentId", String(shipmentId))

	const apiType = type.substring(0, 1).toUpperCase() + type.substring(1)

	for (const file of files) {
		formData.append("files", file)
	}

	const log = await httpRequestJsonResponse<ShipmentLog>(`/api/shipments/upload/${apiType}`, RequestMethod.POST, {
		body: formData,
	})

	return mapApiShipmentLog(log)
}

export async function cancelShipment(shipmentId: Id): Promise<void> {
	await httpRequest(`/api/shipments/${shipmentId}/cancel`, RequestMethod.POST)
}

export async function completeShipment(shipmentId: Id): Promise<void> {
	await httpRequest(`/api/shipments/${shipmentId}/complete`, RequestMethod.POST)
}

export async function getNextShipmentNumber(tfsId: Id): Promise<number> {
	return await httpRequestJsonResponse<number>(`/api/shipments/next/${tfsId}`)
}

export interface SearchShipmentsRequest extends IPagedRequest {
	tfsId: Id
	filter: SearchShipmentsFilter
	sortBy: SearchShipmentsSortBy
	sortDirection: SortDirection
}

export interface SearchShipmentsFilter {
	statuses: ShipmentStatus[]
	hasRecoveryAtFacility: Nullable<boolean>
	hasAdditionalDocuments: Nullable<boolean>
}

export enum SearchShipmentsSortBy {
	ShipmentNumber = "ShipmentNumber",
	Status = "Status",
	DateCreated = "DateCreated",
	NotificationsOfShipment = "NotificationsOfShipment",
	RecoveryAtFacility = "RecoveryAtFacility",
	AdditionalDocuments = "AdditionalDocuments",
	Completed = "Completed",
	Cancelled = "Cancelled",
}

export interface SearchShipmentsResult extends IPagedResult {
	globalTotal: number
	tfsMetadata: TFSEntryMetadata
	logs: ShipmentLog[]
}

export async function searchShipments(request: SearchShipmentsRequest): Promise<SearchShipmentsResult> {
	const result = await httpJsonRequestJsonResponse<SearchShipmentsResult>(
		"/api/shipments/search",
		request,
		RequestMethod.POST
	)
	return {
		...result,
		tfsMetadata: mapApiTFSEntryMetadata(result.tfsMetadata),
		logs: result.logs.map(mapApiShipmentLog),
	}
}
