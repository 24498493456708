import { showErrorMessage } from "@/composition/useLoading"
import usePopups from "@/stores/popupsStore"
import { watchImmediate } from "@vueuse/core"
import { ref, shallowRef, toRef, watch, type FormHTMLAttributes } from "vue"
import {
	Alert,
	ColumnLayout,
	LoadingError,
	ValidationHandler,
	defineComponent,
	optionalProp,
	renderSlot,
	requiredProp,
	useCustomFormValidation,
	type Component,
	type ReactiveComponent,
} from "vue-utils"
import type { BootstrapButtonType } from "./BootstrapButton"
import BootstrapButton from "./BootstrapButton"

export type ValidationResult = string | boolean | Promise<string | boolean>

export interface FormButton {
	id: string
	onSubmit: (event: Event) => void | Promise<void>
	validate?: () => ValidationResult
	validateForm?: boolean

	render: Component<{
		onClick: (e: UIEvent) => void
		disabled: boolean
		isSubmitting: boolean
	}>
}

interface Props {
	buttons: FormButton[]

	updateRef?(form: HTMLFormElement | undefined): void
	onSetupValidation?(handler: ValidationHandler): void
}

export const basicFormButton = (
	options: Omit<FormButton, "render"> & { color: BootstrapButtonType; text: string }
): FormButton => {
	const { color, text, ...buttonOptions } = options
	return {
		render: (props) => (
			<BootstrapButton color={color} {...props}>
				{text}
			</BootstrapButton>
		),
		...buttonOptions,
	}
}

const BasicForm: ReactiveComponent<Props, FormHTMLAttributes> = (props, { attrs, slots }) => {
	const formRef = ref<HTMLFormElement>()
	const buttonSubmitting = shallowRef<FormButton | null>(null)

	const popups = usePopups()
	const validation = useCustomFormValidation({ form: formRef })

	async function runSubmit(event: Event, button: FormButton) {
		buttonSubmitting.value = button
		try {
			await button.onSubmit(event)
		} catch (e) {
			if (e instanceof LoadingError) {
				void showErrorMessage(e.title, e.message)
			} else {
				void showErrorMessage("Unexpected Error", (e as Error).message)
				console.error(e)
			}
		} finally {
			buttonSubmitting.value = null
		}
	}

	async function handleSubmit(event: Event, button: FormButton) {
		event.preventDefault()
		event.stopImmediatePropagation()
		if (buttonSubmitting.value !== null) {
			return
		}

		if (button.validateForm !== false) {
			const result = await validation.validateForm()
			if (!result.successful) {
				if (result.errorMessages?.length) {
					void popups.showAlertPopup(() => (
						<Alert title="Missing Information">
							<ul style={{ margin: 0, listStyle: "none", padding: 0 }}>
								{result.errorMessages?.map((msg, i) => <li key={i}>{msg}</li>)}
							</ul>
						</Alert>
					))
				}
				return
			}
		}

		if (button.validate) {
			const validationResult = await button.validate()

			if (validationResult !== true) {
				if (typeof validationResult === "string") {
					void popups.showAlertPopup(() => validationResult)
				}
				return
			}
		}

		await runSubmit(event, button)
	}

	watch(formRef, (formEl) => props.updateRef?.(formEl))
	watchImmediate(toRef(props, "onSetupValidation"), (setupVal) => setupVal?.(validation))

	return () => (
		<form
			ref={formRef}
			class="basic-form"
			{...attrs}
			onSubmit={(e) => {
				e.preventDefault()
				e.stopImmediatePropagation()
				return false
			}}
		>
			{
				//https://stackoverflow.com/questions/895171/prevent-users-from-submitting-a-form-by-hitting-enter
			}
			<button type="submit" disabled style="display: none" aria-hidden="true" />

			{renderSlot(slots)}
			<hr class="w-full" />
			<div class="flex justify-end">
				<ColumnLayout spacing="1rem" columns={props.buttons.length}>
					{props.buttons.map((button) => (
						<button.render
							key={button.id}
							disabled={buttonSubmitting.value !== null}
							isSubmitting={buttonSubmitting.value !== null && buttonSubmitting.value.id === button.id}
							onClick={(e) => void handleSubmit(e, button)}
						/>
					))}
				</ColumnLayout>
			</div>
		</form>
	)
}

export default defineComponent(BasicForm, {
	buttons: requiredProp(Array),
	updateRef: optionalProp(Function),

	onSetupValidation: optionalProp(Function),
})
