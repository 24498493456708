import type { SystemRole } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { defineComponent, optionalProp, renderSlot, requiredProp, type ReactiveComponent } from "vue-utils"
import BasicPage from "./BasicPage"

interface Props {
	role: SystemRole
	silent?: boolean
}

const AuthorizeRole: ReactiveComponent<Props> = (props, { slots }) => {
	const user = useLoggedInUser()

	return () => {
		const hasPermission = user.atLeastHasRole(props.role)
		if (hasPermission) {
			return renderSlot(slots)
		}
		if (props.silent) {
			return null
		}
		return (
			<BasicPage title="Access Denied">
				You do not have permission to view this page. Please contact an administrator if you believe this is an error
			</BasicPage>
		)
	}
}

export default defineComponent(AuthorizeRole, {
	role: requiredProp(String),
	silent: optionalProp(Boolean),
})
