/* eslint-disable @typescript-eslint/no-namespace */
import {
	DateTimeFormatter,
	DayOfWeek,
	Duration,
	Instant,
	LocalDate,
	LocalDateTime,
	LocalTime,
	ZoneId,
	ZonedDateTime,
	convert,
} from "@js-joda/core"

declare module "@js-joda/core" {
	export interface ZonedDateTime {
		getStartOfWeek(): ZonedDateTime
		getEndOfWeek(): ZonedDateTime
	}

	export interface LocalTime {
		toHTMLInput(): string
	}

	export interface LocalDate {
		getStartOfWeek(): LocalDate
		getEndOfWeek(endDay: DayOfWeek): LocalDate
		toHTMLInput(): string
		toLocalDateString(): string
		toGBDateString(): string
	}

	export namespace LocalDate {
		let fromHtmlInput: (input: string) => LocalDate
	}

	export interface LocalDateTime {
		toHTMLInput(): string
		toLocalDateTimeString(): string
	}

	export namespace LocalDateTime {
		let fromHtmlInput: (input: string) => LocalDateTime
	}

	export interface Instant {
		toUTCDateTimeString(): string
		toHTMLInputUTC(): string
	}

	export namespace Instant {
		let fromHtmlInputUTC: (input: string) => Instant
	}

	export interface Duration {
		toHTMLInput(): string
	}
}

ZonedDateTime.prototype.getStartOfWeek = function () {
	return this.toLocalDate().getStartOfWeek().atStartOfDay().atZone(this.zone())
}
ZonedDateTime.prototype.getEndOfWeek = function () {
	return this.toLocalDate().getEndOfWeek(DayOfWeek.SUNDAY).atStartOfDay().atZone(this.zone())
}

LocalTime.prototype.toHTMLInput = function () {
	return this.format(DateTimeFormatter.ofPattern("HH:mm"))
}
LocalTime.prototype.toJSON = function () {
	return this.format(DateTimeFormatter.ofPattern("HH:mm:ss"))
}

LocalDate.prototype.getStartOfWeek = function () {
	return this.plusDays(-this.dayOfWeek().value() + 1)
}
LocalDate.prototype.getEndOfWeek = function (endDay: DayOfWeek) {
	return this.plusDays(endDay.value() - this.dayOfWeek().value())
}
LocalDate.prototype.toHTMLInput = function () {
	return this.toJSON()
}
LocalDate.prototype.toLocalDateString = function () {
	return convert(this).toDate().toLocaleDateString()
}
LocalDate.prototype.toGBDateString = function () {
	return this.format(DateTimeFormatter.ofPattern("dd/MM/yyyy"))
}
LocalDate.fromHtmlInput = (input) => {
	return LocalDate.parse(input, DateTimeFormatter.ofPattern("yyyy-MM-dd"))
}

LocalDateTime.prototype.toHTMLInput = function () {
	return this.format(DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm"))
}
LocalDateTime.prototype.toLocalDateTimeString = function () {
	const pattern = DateTimeFormatter.ofPattern("yyyy/MM/dd HH:mm")
	return this.format(pattern)
}
LocalDateTime.fromHtmlInput = function (input: string): LocalDateTime {
	return LocalDateTime.parse(input, DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm"))
}

Instant.prototype.toHTMLInputUTC = function () {
	return this.atZone(ZoneId.UTC).toLocalDateTime().toHTMLInput()
}
Instant.prototype.toUTCDateTimeString = function () {
	return this.atZone(ZoneId.UTC).toLocalDateTime().toLocalDateTimeString()
}
Instant.fromHtmlInputUTC = function (input: string): Instant {
	const dt = LocalDateTime.fromHtmlInput(input)
	return dt.atZone(ZoneId.UTC).toInstant()
}

Duration.prototype.toJSON = function () {
	return `PT${this.toHours() % 24}H${this.toMinutes() % 60}M`
}
Duration.prototype.toHTMLInput = function () {
	return LocalTime.of(this.toHours(), this.toMinutes() % 60, this.seconds() % 60).toHTMLInput()
}
