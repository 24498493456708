import { httpJsonRequestJsonResponse } from "@/services/httpService"
import { defineGlobals } from "vue-utils"

export async function generateDownloadLink(uploadGuid: string): Promise<string> {
	return await httpJsonRequestJsonResponse<string>("/api/download/generate-link", uploadGuid)
}

export async function downloadFile(uploadGuid: string) {
	const downloadLink = await generateDownloadLink(uploadGuid)
	window.open(downloadLink, "_blank", "noopener noreferrer")
}

defineGlobals({
	downloadFile,
})
