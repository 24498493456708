import { css } from "vite-css-in-js"
import {
	computed,
	onUpdated,
	shallowRef,
	toRef,
	watch,
	type HTMLAttributes,
	type InputHTMLAttributes,
	type SelectHTMLAttributes,
} from "vue"
import {
	castUnsafe,
	defineComponent,
	optionalProp,
	requiredProp,
	type ConsumedProps,
	type ReactiveComponent,
} from "vue-utils"
import type { JSX } from "vue/jsx-runtime"
import DropdownList, { type DropdownListOption } from "./DropdownList"

export interface MultiSelectProps<T> {
	options: T[]
	selected: T[]
	setSelected(items: T[]): void
	getKey(item: T): number | string
	getText(item: T): string

	getColor?(item: T): string

	entityNames: string

	noneText: string
	allText: string
	someText?: (selected: T[]) => string
	allowSearch?: boolean

	dropdownAttributes?: HTMLAttributes
}

const selectStyles = css`
	cursor: default;
	user-select: none;
	text-overflow: ellipsis;
	padding-right: 1em !important;
`

export const createMultiSelect = <T,>() => {
	const MultiSelect: ReactiveComponent<MultiSelectProps<T>, SelectHTMLAttributes & InputHTMLAttributes> = (
		props,
		{ attrs }
	) => {
		const inputRef = shallowRef<HTMLInputElement | HTMLSelectElement>()
		const searchTerm = shallowRef<string>("")
		const dropdownOpen = shallowRef<boolean>(false)

		const selectedItems = shallowRef(props.selected)
		const selectedIds = computed(() => new Set(selectedItems.value.map((item) => props.getKey(item))))

		watch(toRef(props, "selected"), (selected) => (selectedItems.value = selected))

		const inputText = computed(() => {
			if (selectedItems.value.length === 0) {
				return props.noneText
			}
			if (selectedItems.value.length === props.options.length) {
				return props.allText
			}
			if (props.someText) {
				return props.someText(selectedItems.value)
			}

			const longest = Math.max(
				...props.options.map((option) => props.getText(option).length),
				props.noneText.length,
				props.allText.length
			)

			let text = props.getText(selectedItems.value[0])
			for (let i = 1; i < selectedItems.value.length; i++) {
				const itemText = props.getText(selectedItems.value[i])
				if (text.length + itemText.length + 3 > longest) {
					return `${selectedItems.value.length} ${props.entityNames}`
				}
				text += `, ${itemText}`
			}
			return text
		})

		const selectValue = computed(() => {
			if (selectedItems.value.length === 0) {
				return ""
			}
			if (selectedItems.value.length === props.options.length) {
				return "__multiselect_all_values__"
			}
			if (selectedItems.value.length === 1) {
				return props.getKey(selectedItems.value[0])
			}
			return "__multiselect_multiple_values__"
		})

		onUpdated(() => {
			if (dropdownOpen.value && inputRef.value) {
				inputRef.value.focus()
			}
		})

		function showInput() {
			return dropdownOpen.value && props.allowSearch
		}
		function showSelect() {
			return !showInput()
		}

		function toggleOption(option: T) {
			const key = props.getKey(option)
			if (selectedIds.value.has(key)) {
				selectedItems.value = selectedItems.value.filter((option) => props.getKey(option) !== key)
			} else {
				selectedItems.value = [...selectedItems.value, option]
			}
		}

		function onSelectClick(e: Event) {
			const el = e.target as HTMLSelectElement
			if (dropdownOpen.value) {
				el.blur()
			} else {
				el.focus()
			}
		}

		function openDropdown() {
			dropdownOpen.value = true
			searchTerm.value = ""
		}

		function closeDropdown() {
			dropdownOpen.value = false

			const existingIds = new Set(props.selected.map(props.getKey))
			if (
				existingIds.size !== selectedIds.value.size ||
				Array.from(existingIds).some((id) => !selectedIds.value.has(id))
			) {
				props.setSelected(selectedItems.value)
			}
		}

		function renderSelect() {
			return (
				<select
					key="inputSelect"
					ref={inputRef}
					class={selectStyles}
					value={selectValue.value}
					placeholder={inputText.value}
					onMousedown={(e) => e.preventDefault()}
					onClick={onSelectClick}
					onFocus={openDropdown}
					onBlur={() => showSelect() && closeDropdown()}
					{...attrs}
				>
					<option value="" disabled>
						{props.noneText}
					</option>
					<option value="__multiselect_multiple_values__">{inputText.value}</option>
					<option value="__multiselect_all_values__">{props.allText}</option>

					{props.options.map((option) => (
						<option key={props.getKey(option)} value={props.getKey(option)}>
							{props.getText(option)}
						</option>
					))}
				</select>
			)
		}

		function renderSearch() {
			return (
				<input
					key="inputSearch"
					ref={inputRef}
					v-model={searchTerm.value}
					class={selectStyles}
					placeholder="Type to search"
					onMousedown={(e) => e.preventDefault()}
					onFocus={openDropdown}
					onBlur={() => showInput() && closeDropdown()}
					{...attrs}
				/>
			)
		}

		function renderList() {
			let filteredOptions = props.options
			if (props.allowSearch && searchTerm.value.length > 0) {
				const searchLower = searchTerm.value.toLocaleLowerCase()
				filteredOptions = props.options.filter((option) =>
					props.getText(option).toLocaleLowerCase().includes(searchLower)
				)
			}
			type ExpandedOption = DropdownListOption & { option: T }
			const options = filteredOptions.map<ExpandedOption>((option) => ({
				id: props.getKey(option),
				name: props.getText(option),
				selected: selectedIds.value.has(props.getKey(option)),
				color: props.getColor ? props.getColor(option) : undefined,
				option,
			}))

			return (
				<DropdownList
					options={options}
					toggleSelected={(option) => toggleOption((option as ExpandedOption).option)}
					{...props.dropdownAttributes}
				/>
			)
		}

		return () => (
			<div class="relative">
				{dropdownOpen.value && props.allowSearch ? renderSearch() : renderSelect()}
				{dropdownOpen.value && renderList()}
			</div>
		)
	}

	return defineComponent(MultiSelect, {
		options: requiredProp(Array),
		selected: requiredProp(Array),
		setSelected: requiredProp(Function),
		getKey: requiredProp(Function),
		getText: requiredProp(Function),

		getColor: optionalProp(Function),

		entityNames: requiredProp(String),

		noneText: requiredProp(String),
		allText: requiredProp(String),
		someText: optionalProp(Function),
		allowSearch: optionalProp(Boolean),
		dropdownAttributes: optionalProp(Object),
	})
}

const MultiSelect =
	castUnsafe<<T>(props: ConsumedProps<MultiSelectProps<T>, SelectHTMLAttributes & InputHTMLAttributes>) => JSX.Element>(
		createMultiSelect()
	)
export default MultiSelect
