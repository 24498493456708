import logo from "@/assets/logo.svg"
import { useAppConfig } from "@/stores/configStore"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const nameStyles = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	height: 3rem;
	margin: 0 0.5rem;
	padding-left: 0.3rem;

	img {
		height: 1.9rem;
	}
	span {
		font-size: 1.125rem;
		font-weight: 600;
		color: #f0f0f0;
	}

	border-bottom: thin solid rgba(255, 255, 255, 0.35);

	@media (max-width: 96rem) {
		justify-content: center;
		& > span {
			display: none;
		}
		img {
			height: 2rem;
		}
	}
`

const AppName: ReactiveComponent = () => {
	const config = useAppConfig()

	return () => (
		<div class={nameStyles}>
			<img src={logo} alt="logo" />
			<span>{config.appName}</span>
		</div>
	)
}

export default defineComponent(AppName)
