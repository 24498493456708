import { FileUpload } from "@/components/file-upload"
import { type HTMLAttributes, type InputHTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, useCustomCheck, type ReactiveComponent } from "vue-utils"
import { UploadValidation, type UploadValidationOptions } from "./UploadValidation"

interface Props {
	setFiles(files: File[]): void
	validationOptions: UploadValidationOptions

	initialFiles?: File[]
	inputAttrs?: InputHTMLAttributes
}

const DocumentsFileUpload: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	let files: File[] = []

	useCustomCheck(() => UploadValidation.validateFileUpload(files, props.validationOptions))

	return () => (
		<FileUpload
			initialFiles={props.initialFiles}
			onSelectionChanged={(newFiles) => {
				files = newFiles
				props.setFiles(newFiles)
			}}
			accept={UploadValidation.AllowedMimeTypes}
			inputAttrs={props.inputAttrs}
			multiple
			{...attrs}
		/>
	)
}

export default defineComponent(DocumentsFileUpload, {
	setFiles: requiredProp(Function),
	validationOptions: requiredProp(Object),

	initialFiles: optionalProp(Array),
	inputAttrs: optionalProp(Object),
})
