import SortableHeading from "@/components/table/SortableHeading"
import type { TdHTMLAttributes } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import type { SearchShipmentsSortBy } from "../requests"
import { useShipmentsStore } from "../store"

interface Props {
	text: string
	sort: SearchShipmentsSortBy
}

const SortableShipmentHeading: ReactiveComponent<Props, TdHTMLAttributes> = (props, { attrs }) => {
	const store = useShipmentsStore()
	return () => (
		<SortableHeading
			direction={store.requestOptions.sortBy === props.sort ? store.requestOptions.sortDirection : null}
			setDirection={(dir) => {
				store.requestOptions.sortBy = props.sort
				store.requestOptions.sortDirection = dir
			}}
			align="left"
			{...attrs}
		>
			{props.text}
		</SortableHeading>
	)
}

export default defineComponent(SortableShipmentHeading, {
	text: requiredProp(String),
	sort: requiredProp(String),
})
