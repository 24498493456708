import type { DialogHTMLAttributes } from "vue"
import {
	Modal as Dialog,
	defineComponent,
	optionalProp,
	propsWithDefaults,
	renderSlot,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	zIndex?: number
	onCancel?: (e: Event) => void
}

const Modal: ReactiveComponent<Props, DialogHTMLAttributes> = (initialProps, { attrs, slots }) => {
	const props = propsWithDefaults(initialProps, {
		zIndex: 3,
		onCancel: (e) => e.preventDefault(),
	})

	return () => (
		<Dialog class="flex flex-col" zIndex={props.zIndex} onCancel={props.onCancel} {...attrs}>
			{renderSlot(slots)}
		</Dialog>
	)
}

export default defineComponent(Modal, {
	zIndex: optionalProp(Number),
	onCancel: optionalProp(Function),
})
