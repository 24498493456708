import type { Company, Id } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequest, httpRequestJsonResponse } from "./httpService"

export async function getCompanies(): Promise<Company[]> {
	return await httpRequestJsonResponse<Company[]>("/api/companies")
}

export async function createCompany(company: Company): Promise<Company> {
	return await httpJsonRequestJsonResponse<Company>("/api/companies", company, RequestMethod.POST)
}

export async function updateCompany(company: Company): Promise<Company> {
	return await httpJsonRequestJsonResponse<Company>("/api/companies", company, RequestMethod.PUT)
}

export async function deleteCompany(companyId: Id): Promise<void> {
	await httpRequest(`/api/companies/${companyId}`, RequestMethod.DELETE)
}
