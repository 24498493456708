import type { Company } from "@/models"
import { computed, unref, type ComputedRef, type MaybeRef } from "vue"

export function useCompaniesFiltering(options: {
	companies: MaybeRef<Company[]>
	searchTerm: MaybeRef<string>
}): ComputedRef<Company[]> {
	const { companies, searchTerm: search } = options

	return computed(() => {
		const companiesArray = unref(companies)
		const searchTerm = unref(search).toLowerCase()
		if (searchTerm.length === 0) {
			return companiesArray
		}

		return companiesArray.filter((company) => {
			const name = company.name.toLowerCase()
			return name.includes(searchTerm)
		})
	})
}
