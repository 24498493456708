import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/models"
import { reactive } from "vue"
import { defineComponent, ensureLoadingHasResult, useLoadableRef, type ReactiveComponent } from "vue-utils"
import AuthorizeRole from "../AuthorizeRole"
import BasicPage from "../BasicPage"
import UsersTable from "./UsersTable"
import { getUsers } from "./requests"

const UsersPage: ReactiveComponent = () => {
	const usersRef = useLoadableRef(async () => {
		const users = await getUsers()
		return reactive(users)
	})

	return () => (
		<AuthorizeRole role={SystemRole.Administrator}>
			<BasicPage title="Manage Users">
				<ContentLoading stores={[usersRef]}>
					<h5>Creating new Users</h5>
					<p>
						Users will automatically be populated into this table after logging in to the system.
						<br />
						If you need to add a new user to the system, please ask them to register with a new account by accessing the
						system and creating a new account first.
					</p>
					<hr />
					<UsersTable users={ensureLoadingHasResult(usersRef)} />
				</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(UsersPage)
