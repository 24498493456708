import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import { showErrorMessage } from "@/composition/useLoading"
import usePopups from "@/stores/popupsStore"
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import { useVueAuth } from "oidc-vue"
import { useRouter } from "vue-router"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"

const LogoutButton: ReactiveComponent = () => {
	const router = useRouter()
	const popups = usePopups()
	const { manager } = useVueAuth()

	async function logout() {
		const confirmation = await popups.confirm(() => (
			<Alert title="Confirm Logout">Are you sure you wish to log out of your account?</Alert>
		))
		if (!confirmation) {
			return
		}

		try {
			await manager.signoutRedirect()
		} catch (e) {
			console.error(e)
			await showErrorMessage("Failed to log out", "Unable to log out. Try again later or contact an admin")
		}
		await router.replace("/")

		localStorage.clear()
		location.reload()
	}

	return () => (
		<BootstrapButton color="danger" onClick={() => void logout()}>
			<Icon icon={faArrowRightFromBracket} />
			<span>Log Out</span>
		</BootstrapButton>
	)
}

export default defineComponent(LogoutButton)
