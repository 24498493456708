import {
	faFile,
	faFileCode,
	faFileExcel,
	faFileImage,
	faFilePdf,
	faFilePowerpoint,
	faFileText,
	faFileVideo,
	faFileWord,
	faFileZipper,
} from "@fortawesome/free-regular-svg-icons"
import { faFileAudio, faFileCsv } from "@fortawesome/free-solid-svg-icons"

const MimeTypes = {
	Word: [
		"application/msword",
		"application/vnd.oasis.opendocument.text",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	],
	Spreadsheet: [
		"application/vnd.ms-excel",
		"application/vnd.oasis.opendocument.spreadsheet",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	],
	Powerpoint: [
		"application/vnd.ms-powerpoint",
		"application/vnd.oasis.opendocument.presentation",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	],
	Code: [
		"text/css",
		"text/javascript",
		"text/json",
		"text/xml",
		"text/html",
		"text/xml",
		"application/json",
		"application/xml",
		"application/x-sh",
	],
	PDF: "application/pdf",
	CSV: "text/csv",
	ZIP: "application/zip",
	PlainText: "text/plain",
} satisfies Record<string, string | string[]>

export function getFileIcon(mimeType: string) {
	mimeType = mimeType.toLowerCase()

	if (mimeType.startsWith("audio/")) return faFileAudio
	else if (mimeType.startsWith("image/")) return faFileImage
	else if (mimeType.startsWith("video/")) return faFileVideo
	else if (MimeTypes.Word.includes(mimeType)) return faFileWord
	else if (MimeTypes.Spreadsheet.includes(mimeType)) return faFileExcel
	else if (MimeTypes.Powerpoint.includes(mimeType)) return faFilePowerpoint
	else if (MimeTypes.Code.includes(mimeType)) return faFileCode
	else if (MimeTypes.PDF === mimeType) return faFilePdf
	else if (MimeTypes.CSV === mimeType) return faFileCsv
	else if (MimeTypes.ZIP === mimeType) return faFileZipper
	else if (MimeTypes.PlainText === mimeType) return faFileText

	return faFile
}
