import { onBeforeMount } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const HomePage: ReactiveComponent = () => {
	const router = useRouter()
	onBeforeMount(() => router.replace("/tfs-entries"))

	return () => null
}

export default defineComponent(HomePage)
