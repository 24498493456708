import { useCompanies } from "@/stores/companiesStore"
import usePopups from "@/stores/popupsStore"
import { Alert } from "vue-utils"
import type { TFSFormContext } from "./context"

export function useTFSValidation(context: TFSFormContext) {
	const popups = usePopups()
	const companies = useCompanies()

	async function confirmDeleteTFS(): Promise<boolean> {
		return await popups.confirm({
			content: () => (
				<Alert title="Confirm delete TFS">
					Are you <strong>certain</strong> that you wish to delete this TFS ({context.existingTFS?.tfsNumber})?
					<br />
					<br />
					The TFS will be removed from the system completely.
					<br />
					This action cannot be undone.
				</Alert>
			),
			width: "32rem",
			confirmText: "Yes, Delete this TFS",
			cancelText: "No, Don't Delete",
		})
	}

	async function confirmCloseTFS(closingFiles: File[]): Promise<boolean> {
		if (closingFiles.length === 0) {
			return await popups.confirm(() => (
				<Alert title="Confirm close TFS with no documents?">
					<p>Are you sure you wish to close this TFS without uploading any documentation?</p>
					<p>The TFS will be closed and you will not be able to upload any further documentation.</p>
				</Alert>
			))
		}

		return await popups.confirm(() => (
			<Alert title="Confirm close TFS">
				<p>
					Please confirm you have uploaded <i>all</i> corresponding documentation regarding closing the TFS.
				</p>
			</Alert>
		))
	}

	async function confirmSaveWithConsentDocuments(): Promise<boolean> {
		const companyName = companies.get(context.formData.companyId)?.name ?? "the associated company"
		const companyWording = (
			<p>
				This action will make this TFS visible to {companyName}, and be locked for editing. Shipments will be able to
				commence during the valid dates.
			</p>
		)

		if (context.formData.consentIssuedDocuments.length === 0) {
			return await popups.confirm({
				content: () => (
					<Alert title="Confirm Consent Issued with no documents?">
						<p>Are you sure you wish to confirm Consent Issued without uploading any documentation?</p>
						<p>Consent will be issued for the TFS and you will not be able to upload any further documentation.</p>
						<div class="mt-4">{companyWording}</div>
					</Alert>
				),
				confirmText: "Confirm",
				width: "32rem",
			})
		}

		return await popups.confirm(() => (
			<Alert title="Confirm Consent Issued for this TFS">
				<p>
					Please confirm you have uploaded <i>all</i> corresponding documentation regarding Consent Issued for this TFS.
				</p>
				<div class="mt-4">{companyWording}</div>
			</Alert>
		))
	}

	async function confirmChangeShipmentTotals(): Promise<boolean> {
		return await popups.confirm({
			content: () => (
				<Alert title="Confirm change of Shipments">
					<p>Please confirm that you wish to change the shipment totals for this TFS.</p>
					<p>
						This{" "}
						<strong>
							<i>will</i>
						</strong>{" "}
						affect the financial deposit.
					</p>
				</Alert>
			),
			width: "32rem",
			confirmText: "Confirm Change",
		})
	}

	async function validateSave(): Promise<boolean> {
		const existing = context.existingTFS
		const formData = context.formData

		const existingConsentIssued = !!existing?.consentIssued
		if (!existingConsentIssued && formData.consentIssued) {
			return await confirmSaveWithConsentDocuments()
		}

		if (!existing) {
			return true
		}

		if (
			existing.maximumShipments !== formData.maximumShipments ||
			existing.maximumActiveShipments !== formData.maximumActiveShipments
		) {
			return await confirmChangeShipmentTotals()
		}

		return true
	}

	return {
		confirmDeleteTFS,
		confirmCloseTFS,
		confirmSaveWithConsentDocuments,
		confirmChangeShipmentTotals,
		validateSave,
	}
}
