import { renderSlot, type ButtonHTMLAttributes } from "vue"
import { defineComponent, optionalProp, propsWithDefaults, requiredProp, type ReactiveComponent } from "vue-utils"

export type BootstrapButtonType = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark"

export interface Props {
	color: BootstrapButtonType
	plain?: boolean
	isSubmitting?: boolean
}

const BootstrapButton: ReactiveComponent<Props, ButtonHTMLAttributes> = (initialProps, { slots, attrs }) => {
	const props = propsWithDefaults(initialProps, {
		isSubmitting: false,
		plain: false,
	})

	return () => (
		<button
			class={[
				"btn",
				`btn-${props.color}`,
				"inline-flex",
				"justify-center",
				"items-center",
				"spacing-2",
				props.plain && "btn-plain",
			]}
			{...attrs}
			disabled={(attrs as ButtonHTMLAttributes).disabled || props.isSubmitting}
		>
			{props.isSubmitting && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
			{renderSlot(slots, "default")}
		</button>
	)
}

export default defineComponent(BootstrapButton, {
	color: requiredProp(String),
	plain: optionalProp(Boolean),
	isSubmitting: optionalProp(Boolean),
})
