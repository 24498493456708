import type { GenericEntity, Id, Nullable } from "./GenericEntity"

export interface User extends GenericEntity {
	guid: string
	firstName: string
	lastName: string
	emailAddress: string
	role: SystemRole
	loginEnabled: boolean
	companyId: Nullable<Id>
}

export enum SystemRole {
	SuperAdmin = "SuperAdmin",
	Administrator = "Administrator",
	User = "User",
	ReadOnly = "ReadOnly",
}

const rolePriorities: Record<SystemRole, number> = {
	[SystemRole.SuperAdmin]: 100,
	[SystemRole.Administrator]: 50,
	[SystemRole.User]: 10,
	[SystemRole.ReadOnly]: 1,
}

/**
 * @returns A number value of the role's priority. Higher values indicate a higher level of role
 */
export function getRolePriority(role: SystemRole): number {
	return rolePriorities[role]
}
