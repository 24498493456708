import ColumnTable from "@/components/table/ColumnTable"
import { ShipmentStatus, getShipmentStatusColor, type ShipmentLog } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { Instant, ZoneId } from "@js-joda/core"
import { css } from "vite-css-in-js"
import type { TdHTMLAttributes } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { SearchShipmentsSortBy } from "../requests"
import { useShipmentsStore } from "../store"
import CancelShipmentColumn from "./CancelShipmentColumn"
import CompleteShipmentColumn from "./CompleteShipmentColumn"
import DocumentsColumn from "./DocumentsColumn"
import SortableShipmentHeading from "./SortableShipmentHeading"

const tableStyles = css`
	tr td {
		background-color: var(--shipment-row-color) !important;
	}
	tr[data-status="Cancelled"] > td {
		text-decoration: line-through;
		color: #555555;
	}
`

const ShipmentsTable: ReactiveComponent = () => {
	const store = useShipmentsStore()

	function formatDate(instant: Instant) {
		return instant.atZone(ZoneId.SYSTEM).toLocalDate().toGBDateString()
	}

	function getStatus(shipment: ShipmentLog) {
		if (shipment.cancelled) return ShipmentStatus.Cancelled
		if (shipment.completed) return ShipmentStatus.Completed
		return ShipmentStatus.Active
	}

	return () => (
		<ColumnTable
			getKey={(shipment) => shipment.id}
			entities={store.getResults().logs}
			rowAttributes={(shipment) => {
				const status = getStatus(shipment)
				return {
					"data-status": status,
					style: { "--shipment-row-color": getShipmentStatusColor(status) },
				} as TdHTMLAttributes
			}}
			class={tableStyles}
			columns={{
				shipmentNumber: {
					label: "Shipment Number",
					size: "max-content",
					renderTh: () => (
						<SortableShipmentHeading sort={SearchShipmentsSortBy.ShipmentNumber} text="Shipment Number" />
					),
					renderTd: ({ item }) => <td style={{ textDecoration: "none" }}>{item.shipmentNumber}</td>,
				},
				status: {
					label: "Status",
					size: "minmax(max-content, 0.5fr)",
					renderTh: () => <SortableShipmentHeading sort={SearchShipmentsSortBy.Status} text="Status" />,
					renderTd: ({ item }) => <td style={{ textDecoration: "none" }}>{formatEnum(getStatus(item))}</td>,
				},
				dateCreated: {
					label: "Date Created",
					size: "minmax(max-content, 0.75fr)",
					renderTh: () => <SortableShipmentHeading sort={SearchShipmentsSortBy.DateCreated} text="Date Created" />,
					renderContent: ({ item }) => formatDate(item.createdBy.timeStamp),
				},
				notificationOfShipments: {
					label: "Notification of Shipments",
					size: "minmax(max-content, 1fr)",
					renderTh: () => (
						<SortableShipmentHeading
							sort={SearchShipmentsSortBy.NotificationsOfShipment}
							text="Notification of Shipments"
						/>
					),
					renderTd: ({ item }) => <DocumentsColumn shipment={item} type="notificationOfShipments" />,
				},
				recoveryAtFacility: {
					label: "Recovery At Facility",
					size: "minmax(max-content, 1fr)",
					renderTh: () => (
						<SortableShipmentHeading sort={SearchShipmentsSortBy.RecoveryAtFacility} text="Recovery At Facility" />
					),
					renderTd: ({ item }) => <DocumentsColumn shipment={item} type="recoveryAtFacility" />,
				},
				additionalDocuments: {
					label: "Additional Documents",
					size: "minmax(max-content, 1fr)",
					renderTh: () => (
						<SortableShipmentHeading sort={SearchShipmentsSortBy.AdditionalDocuments} text="Additional Documents" />
					),
					renderTd: ({ item }) => <DocumentsColumn shipment={item} type="additionalDocuments" />,
				},
				cancelled: {
					label: "Cancelled?",
					size: "minmax(8rem, 0.35fr)",
					renderTh: () => <SortableShipmentHeading sort={SearchShipmentsSortBy.Cancelled} text="Cancelled?" />,
					renderTd: ({ item }) => <CancelShipmentColumn shipment={item} />,
				},
				completed: {
					label: "Completed?",
					size: "minmax(8rem, 0.35fr)",
					renderTh: () => <SortableShipmentHeading sort={SearchShipmentsSortBy.Completed} text="Completed?" />,
					renderTd: ({ item }) => <CompleteShipmentColumn shipment={item} />,
				},
			}}
		/>
	)
}

export default defineComponent(ShipmentsTable)
