import type { WasteShippingConfig } from "@/models"
import { sendHttpRequest } from "@/services/httpHelper"
import { reactive } from "vue"

async function readJson<T>(url: string) {
	const result = await sendHttpRequest(url)
	return (await result.json()) as T
}

const config: WasteShippingConfig = reactive(await readJson<WasteShippingConfig>("/api/config.json"))
document.title = `Guernsey ${config.appName}`

export function useAppConfig(): WasteShippingConfig {
	return config
}
