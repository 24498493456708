import type { KeysMatching } from "vue-utils"
import type { ApiTypeOf } from "./ApiType"
import { mapApiDocumentUpload, type DocumentUpload } from "./DocumentUpload"
import type { GenericEntity, Id, Nullable } from "./GenericEntity"
import { mapApiUserTimeStamp, type UserTimeStamp } from "./UserTimeStamp"

export type ShipmentLogDocumentKeys = KeysMatching<ShipmentLog, Nullable<DocumentUpload[]>>

export interface ShipmentLog extends GenericEntity {
	tfsEntryId: Id

	/** nth shipment for the associated TFS. Starts at 1 */
	shipmentNumber: number

	notificationOfShipments: Nullable<DocumentUpload[]>
	recoveryAtFacility: Nullable<DocumentUpload[]>
	additionalDocuments: Nullable<DocumentUpload[]>

	createdBy: UserTimeStamp

	cancelled: Nullable<UserTimeStamp>
	completed: Nullable<UserTimeStamp>
}

export function mapApiShipmentLog(log: ApiTypeOf<ShipmentLog>): ShipmentLog {
	return {
		...log,
		notificationOfShipments: log.notificationOfShipments?.map(mapApiDocumentUpload) ?? null,
		recoveryAtFacility: log.recoveryAtFacility?.map(mapApiDocumentUpload) ?? null,
		additionalDocuments: log.additionalDocuments?.map(mapApiDocumentUpload) ?? null,

		createdBy: mapApiUserTimeStamp(log.createdBy),
		cancelled: log.cancelled ? mapApiUserTimeStamp(log.cancelled) : null,
		completed: log.completed ? mapApiUserTimeStamp(log.completed) : null,
	}
}

export enum ShipmentStatus {
	Active = "Active",
	Completed = "Completed",
	Cancelled = "Cancelled",
}

const shipmentStatusColors: Record<ShipmentStatus, string> = {
	[ShipmentStatus.Active]: "#d5ffc1",
	[ShipmentStatus.Completed]: "#ffe4b5",
	[ShipmentStatus.Cancelled]: "#ffd9d9",
}

export function getShipmentStatusColor(status: ShipmentStatus) {
	return shipmentStatusColors[status]
}
