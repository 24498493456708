import Icon from "@/components/Icon"
import type { FormButton } from "@/components/form/BasicForm"
import BootstrapButton from "@/components/form/BootstrapButton"
import { SystemRole } from "@/models"
import { canEditAnyTFSField } from "@/pages/tfs-entries/permissions"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { faArrowRight, faCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { computed } from "vue"
import { runWithMinimumDelay } from "vue-utils"
import { validateCreatingTFS, validateUpdatingTFS } from "../../requests"
import { TFSFormSection, useTFSFormContext } from "../context"

export function useTFSFormButtons() {
	const context = useTFSFormContext()

	const loggedInUser = useLoggedInUser()
	const canEdit = () => canEditAnyTFSField(context.existingTFS, loggedInUser)

	return computed<FormButton[]>(() => {
		const buttons: FormButton[] = []

		if (context.existingTFS && canEdit() && !context.existingTFS.consentIssued) {
			buttons.push({
				id: "delete",
				validateForm: false,
				validate: context.validation.confirmDeleteTFS,
				onSubmit: () => context.delete(),
				render: (btnProps) => (
					<BootstrapButton color="danger" {...btnProps}>
						<Icon icon={faTrashAlt} />
						Delete
					</BootstrapButton>
				),
			})
		}
		buttons.push({
			id: "cancel",
			validateForm: false,
			onSubmit: () => context.cancel(),
			render: (btnProps) => (
				<BootstrapButton color="secondary" {...btnProps}>
					{canEdit() ? "Cancel" : "Close"}
				</BootstrapButton>
			),
		})

		if (canEdit()) {
			const existingConsentIssued = !!context.existingTFS?.consentIssued
			const issuingConsent = !existingConsentIssued && context.formData.consentIssued

			if (issuingConsent) {
				buttons.push({
					id: "next",
					validateForm: true,
					async onSubmit() {
						const action = context.existingTFS
							? validateUpdatingTFS(context.existingTFS.id, context.formData)
							: validateCreatingTFS(context.formData)
						await runWithMinimumDelay(action, 750)
						context.section = TFSFormSection.ConsentIssued
					},
					render: (btnProps) => (
						<BootstrapButton color="primary" {...btnProps}>
							Next
							<Icon icon={faArrowRight} />
						</BootstrapButton>
					),
				})
			} else {
				buttons.push({
					id: "submit",
					validateForm: true,
					validate: context.validation.validateSave,
					onSubmit: () => context.save(),
					render: (btnProps) => (
						<BootstrapButton color="primary" {...btnProps}>
							{context.type === "create" ? "Create" : "Save"}
						</BootstrapButton>
					),
				})
			}
		}

		if (
			context.existingTFS &&
			context.existingTFS.consentIssued &&
			!context.existingTFS.closed &&
			loggedInUser.atLeastHasRole(SystemRole.Administrator)
		) {
			buttons.push({
				id: "close",
				validateForm: false,
				onSubmit() {
					context.showingClosePopup = true
				},
				render: (btnProps) => (
					<BootstrapButton color="success" {...btnProps}>
						Close TFS
						<Icon icon={faCheck} />
					</BootstrapButton>
				),
			})
		}

		return buttons
	})
}
