export interface TFSSearchResult extends IPagedResult {
	globalTotal: number
	entries: TFSEntryMetadata[]
}
import {
	TFSStatus,
	type IPagedRequest,
	type IPagedResult,
	type Id,
	type Nullable,
	type SortDirection,
	type TFSEntryMetadata,
} from "@/models"
import type { LocalDate } from "@js-joda/core"

export interface TFSSearchRequest extends IPagedRequest {
	filter: TFSSearchFilter
	sortBy: Nullable<TFSSearchSorting>
	sortDirection: Nullable<SortDirection>
}

export interface TFSSearchFilter {
	companyIds: Id[]
	consentIssued: Nullable<boolean>
	statuses: TFSStatus[]
	tfsNumber: Nullable<string>
	wkNumber: Nullable<string>
}

export enum TFSSearchSorting {
	TFSNumber = "TFSNumber",
	WKNumber = "WKNumber",
	CompanyName = "CompanyName",
	ValidFrom = "ValidFrom",
	ValidTo = "ValidTo",
	ConsentIssued = "ConsentIssued",
	MaximumShipments = "MaximumShipments",
	MaximumActiveShipments = "MaximumActiveShipments",
	CancelledShipments = "CancelledShipments",
	CompletedShipments = "CompletedShipments",
	ActiveShipments = "ActiveShipments",
	TotalShipments = "TotalShipments",
	Status = "Status",
}

export interface UpdateTFSData {
	validFrom: Nullable<LocalDate>
	validTo: Nullable<LocalDate>
	maximumShipments: Nullable<number>
	maximumActiveShipments: Nullable<number>
	consentIssued: boolean
	consentIssuedDocuments: File[]
	notes: string
}

export interface CreateTFSData extends UpdateTFSData {
	tfsNumber: string
	wkNumber: string
	companyId: Id
}
