import { showErrorMessage, useLoading } from "@/composition/useLoading"
import type { Id, TFSEntry } from "@/models"
import { HttpLoadingError } from "@/services/httpHelper"
import { watchImmediate } from "@vueuse/core"
import { computed, reactive, ref, shallowRef } from "vue"
import { getTFSEntry } from "../requests"

export function useEditingTFS() {
	const { runAction } = useLoading()

	const editingTFSId = ref<Id | null>(null)
	const editingTFS = shallowRef<TFSEntry | null>(null)
	const creatingTFS = ref(false)

	async function loadTfs(id: Id) {
		try {
			const tfs = await getTFSEntry(id)
			editingTFS.value = reactive(tfs) as TFSEntry
		} catch (e) {
			closeTFSPopup()
			if (e instanceof HttpLoadingError && e.statusCode === 404) {
				void showErrorMessage("Unknown TFS", "The TFS entry you requested could not be found")
			} else {
				throw e
			}
		}
	}

	function openTFS(id: Id) {
		editingTFSId.value = id
	}

	function closeTFSPopup() {
		editingTFSId.value = null
		editingTFS.value = null
		creatingTFS.value = false
	}

	function openNewTFS() {
		closeTFSPopup()
		creatingTFS.value = true
	}

	watchImmediate(editingTFSId, (tfsId) => {
		if (!editingTFSId.value) {
			editingTFS.value = null
		} else {
			void runAction(loadTfs(tfsId!))
		}
	})

	return {
		editingTFSId,
		openTFS,
		openNewTFS,
		closeTFSPopup,
		creatingTFS: computed<boolean>(() => creatingTFS.value),
		editingTFS: computed<TFSEntry | null>(() => editingTFS.value),
	}
}
