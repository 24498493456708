import { computed, renderSlot, type AnchorHTMLAttributes } from "vue"
import { RouterLink } from "vue-router"
import { defineComponent, optionalProp, propsWithDefaults, requiredProp, type ReactiveComponent } from "vue-utils"
import type { BootstrapButtonType } from "./BootstrapButton"

interface Props {
	color: BootstrapButtonType
	href: string
	plain?: boolean
	nonRoute?: boolean
}

const AnchorButton: ReactiveComponent<Omit<Props, "isSubmitting">, AnchorHTMLAttributes> = (
	initialProps,
	{ slots, attrs }
) => {
	const props = propsWithDefaults(initialProps, {
		plain: false,
		nonRoute: false,
	})

	const classNames = computed(() => {
		const classNames = ["btn", `btn-${props.color}`, "inline-flex", "justify-center", "items-center", "spacing-2"]
		if (props.plain) {
			classNames.push("btn-plain")
		}
		return classNames
	})

	return () =>
		props.nonRoute ? (
			<a href={props.href} class={classNames.value} {...attrs}>
				{renderSlot(slots, "default")}
			</a>
		) : (
			<RouterLink class={classNames.value} to={props.href} {...attrs}>
				{renderSlot(slots, "default")}
			</RouterLink>
		)
}

export default defineComponent(AnchorButton, {
	color: requiredProp(String),
	href: requiredProp(String),
	plain: optionalProp(Boolean),
	nonRoute: optionalProp(Boolean),
})
