import {
	mapApiDocumentUpload,
	mapApiTFSEntry,
	mapApiTFSEntryMetadata,
	type DocumentUpload,
	type Id,
	type TFSEntry,
	type TFSEntryMetadata,
} from "@/models"
import {
	RequestMethod,
	httpJsonRequestJsonResponse,
	httpRequest,
	httpRequestJsonResponse,
} from "@/services/httpService"
import type { CreateTFSData, TFSSearchRequest, TFSSearchResult, UpdateTFSData } from "./requestModels"

export * from "./requestModels"

export async function searchTFSMetadata(request: TFSSearchRequest): Promise<TFSSearchResult> {
	const result = await httpJsonRequestJsonResponse<TFSSearchResult>(
		"/api/tfs-metadata/search",
		request,
		RequestMethod.POST
	)
	return {
		...result,
		entries: result.entries.map(mapApiTFSEntryMetadata),
	}
}

export async function getTFSEntryMetadata(id: Id): Promise<TFSEntryMetadata> {
	const result = await httpRequestJsonResponse<TFSEntryMetadata>(`/api/tfs-metadata/${id}`)
	return mapApiTFSEntryMetadata(result)
}

export async function getTFSEntry(id: Id): Promise<TFSEntry> {
	const result = await httpRequestJsonResponse<TFSEntry>(`/api/tfs-entries/${id}`)
	return mapApiTFSEntry(result)
}

export async function createTFSEntry(data: CreateTFSData): Promise<TFSEntry> {
	const formData = mapCreateTFSDataToForm(data)
	const result = await httpRequestJsonResponse<TFSEntry>(`/api/tfs-entries`, RequestMethod.POST, { body: formData })
	return mapApiTFSEntry(result)
}

export async function validateCreatingTFS(data: CreateTFSData): Promise<void> {
	const formData = mapCreateTFSDataToForm({
		...data,
		consentIssuedDocuments: [], //Avoid sending data that's not needed
	})
	await httpRequest(`/api/tfs-entries/validate`, RequestMethod.POST, { body: formData })
}

export async function updateTFSEntry(tfsId: Id, data: UpdateTFSData): Promise<TFSEntry> {
	const formData = mapUpdateTFSDataToForm(data)
	const result = await httpRequestJsonResponse<TFSEntry>(`/api/tfs-entries/${tfsId}`, RequestMethod.PUT, {
		body: formData,
	})
	return mapApiTFSEntry(result)
}

export async function validateUpdatingTFS(tfsId: Id, data: UpdateTFSData): Promise<void> {
	const formData = mapUpdateTFSDataToForm({
		...data,
		consentIssuedDocuments: [], // Avoid sending data that's not needed
	})
	await httpRequest(`/api/tfs-entries/${tfsId}/validate`, RequestMethod.PUT, { body: formData })
}

export async function closeTFSEntry(tfsId: Id, files: File[]): Promise<TFSEntry> {
	const formData = new FormData()
	for (const file of files) {
		formData.append("ClosingDocuments", file)
	}

	const closedTfs = await httpRequestJsonResponse<TFSEntry>(`/api/tfs-entries/${tfsId}/close`, RequestMethod.POST, {
		body: formData,
	})

	return mapApiTFSEntry(closedTfs)
}

export async function deleteTFSEntry(tfsId: Id): Promise<void> {
	await httpRequest(`/api/tfs-entries/${tfsId}`, RequestMethod.DELETE)
}

export async function uploadTFSAdditionalDocuments(tfsId: Id, documents: File[]): Promise<DocumentUpload[]> {
	const formData = new FormData()
	for (const file of documents) {
		formData.append("AdditionalDocuments", file)
	}

	const uploadedDocs = await httpRequestJsonResponse<DocumentUpload[]>(
		`/api/tfs-entries/${tfsId}/additional-documents`,
		RequestMethod.PUT,
		{
			body: formData,
		}
	)

	return uploadedDocs.map(mapApiDocumentUpload)
}

function mapCreateTFSDataToForm(createData: CreateTFSData): FormData {
	const updateData = mapUpdateTFSDataToForm(createData)
	updateData.set("TFSNumber", createData.tfsNumber)
	updateData.set("WKNumber", createData.wkNumber)
	updateData.set("CompanyId", String(createData.companyId))
	return updateData
}

function mapUpdateTFSDataToForm(saveData: UpdateTFSData): FormData {
	const formData = new FormData()
	if (saveData.validFrom) {
		formData.set("ValidFrom", saveData.validFrom.toJSON())
	}
	if (saveData.validTo) {
		formData.set("ValidTo", saveData.validTo.toJSON())
	}
	formData.set("ConsentIssued", String(saveData.consentIssued))
	if (saveData.maximumShipments !== null) {
		formData.set("MaximumShipments", String(saveData.maximumShipments))
	}
	if (saveData.maximumActiveShipments !== null) {
		formData.set("MaximumActiveShipments", String(saveData.maximumActiveShipments))
	}
	if (saveData.notes) {
		formData.set("Notes", saveData.notes)
	}

	for (const file of saveData.consentIssuedDocuments) {
		formData.append("ConsentIssuedDocuments", file)
	}
	return formData
}
