import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/models"
import { useCompaniesStore } from "@/stores/companiesStore"
import { assignLoadable, defineComponent, loadLoadable, useLoadableRef, type ReactiveComponent } from "vue-utils"
import AuthorizeRole from "../AuthorizeRole"
import BasicPage from "../BasicPage"
import CompaniesTable from "./CompaniesTable"

const CompaniesPage: ReactiveComponent = () => {
	const companiesStore = useCompaniesStore()
	const refreshRef = useLoadableRef(async () => {
		const data = await companiesStore.queryData()
		assignLoadable(companiesStore, {
			type: "done",
			result: data,
		})
		return data
	})
	void loadLoadable(refreshRef)

	return () => (
		<AuthorizeRole role={SystemRole.Administrator}>
			<BasicPage title="Manage Companies" style={{ minWidth: "48rem" }}>
				<ContentLoading stores={[refreshRef]}>
					<CompaniesTable />
				</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(CompaniesPage)
