import { Instant } from "@js-joda/core"
import type { ApiTypeOf, Id, Nullable, User } from "."

export interface UserTimeStamp {
	userId: Id
	timeStamp: Instant
	user: Nullable<User>
}

export function mapApiUserTimeStamp(timeStamp: ApiTypeOf<UserTimeStamp>): UserTimeStamp {
	return {
		...timeStamp,
		timeStamp: Instant.parse(timeStamp.timeStamp),
	}
}
