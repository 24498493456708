import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import BootstrapButton from "@/components/form/BootstrapButton"
import { StateLoading } from "@/components/loading/StateLoading"
import { useLoading } from "@/composition/useLoading"
import { type TFSEntryMetadata } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { faCirclePlus, faFilter } from "@fortawesome/free-solid-svg-icons"
import { computed, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import ShipmentResultsDisplay from "./ShipmentResultsDisplay"
import ShipmentsFilter from "./components/ShipmentsFilter"
import TFSInfo from "./components/TFSInfo"
import CreateShipmentForm from "./documents/CreateShipmentForm"
import * as Permissions from "./permissions"
import { setupShipmentsStore } from "./store"
import { bindShipmentsRequestToQuery } from "./useSearchShipments"

interface Props {
	tfs: TFSEntryMetadata
}

export interface ShipmentDetails {
	shipmentNumber: number
}

const ManageShipments: ReactiveComponent<Props> = (props) => {
	const store = setupShipmentsStore(props.tfs)
	bindShipmentsRequestToQuery(store.requestOptions)

	const { runAction } = useLoading()

	const loggedInUser = useLoggedInUser()

	const creatingShipment = ref<ShipmentDetails | null>(null)
	const canCreateShipment = computed(() => Permissions.canCreateShipment(loggedInUser, store.tfs))

	async function handleCreate() {
		const shipmentNumber = await runAction(store.getNextShipmentNumber(store.tfs.id))
		creatingShipment.value = {
			shipmentNumber,
		}
	}

	return () => (
		<>
			<h5 class="mb-1 flex items-center flex-wrap spacing-2">
				<span class="flex-grow-1">Filter Shipments</span>
				{canCreateShipment.value && (
					<BootstrapButton color="success" onClick={() => void handleCreate()}>
						<Icon icon={faCirclePlus} />
						<span>Create Shipment</span>
					</BootstrapButton>
				)}
				<BootstrapButton color="danger" onClick={() => store.resetSearchOptions()}>
					<Icon icon={faFilter} />
					<span>Reset Filters</span>
				</BootstrapButton>
			</h5>

			<ShipmentsFilter />
			<hr />

			<TFSInfo tfs={store.tfs} />
			<hr />

			<StateLoading stores={[store.shipmentsRef]}></StateLoading>
			{store.hasResults() && (
				<>
					<h5>Shipments</h5>
					<ShipmentResultsDisplay />
				</>
			)}

			{creatingShipment.value && (
				<Modal style={{ width: "60rem" }}>
					<CreateShipmentForm
						shipmentDetails={creatingShipment.value}
						cancel={() => (creatingShipment.value = null)}
						createShipment={async (shipmentNumber, files) => {
							const result = await store.createShipment(shipmentNumber, files)
							creatingShipment.value = null
							return result
						}}
					/>
				</Modal>
			)}
		</>
	)
}

export default defineComponent(ManageShipments, {
	tfs: requiredProp(Object),
})
