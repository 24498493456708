import type { User } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "@/services/httpService"

export async function getUsers(): Promise<User[]> {
	return await httpRequestJsonResponse<User[]>("/api/users")
}

export async function updateUser(user: User): Promise<User> {
	return await httpJsonRequestJsonResponse<User>("/api/users", user, RequestMethod.PUT)
}
