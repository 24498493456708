import { useAppConfig } from "@/stores/configStore"
import type { ReactiveComponent } from "vue-utils"
import { defineComponent } from "vue-utils"
import BasicPage from "./BasicPage"

const LoginNotEnabledPage: ReactiveComponent = () => {
	const config = useAppConfig()

	return () => (
		<BasicPage title="Login Disabled" noNav>
			<p style={{ fontSize: "1.125rem" }}>
				Your account has not yet been enabled.
				<br />
				<br />
				An automatic email has been sent to {config.officeName}, and your account should be enabled shortly.
				<br />
				If you do not get a response, please email <a href={`mailto:${config.officeEmail}`}>{config.officeEmail}</a>.
			</p>
		</BasicPage>
	)
}

export default defineComponent(LoginNotEnabledPage)
