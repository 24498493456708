import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import { useLoading } from "@/composition/useLoading"
import type { ShipmentLog } from "@/models"
import { useAppConfig } from "@/stores/configStore"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import usePopups from "@/stores/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { ZoneId, type Instant } from "@js-joda/core"
import { computed } from "vue"
import { Alert, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { canEditDocuments } from "../permissions"
import { useShipmentsStore } from "../store"

interface Props {
	shipment: ShipmentLog
}

const CancelShipmentColumn: ReactiveComponent<Props> = (props) => {
	const shipment = proxyProp(props, "shipment")
	const store = useShipmentsStore()
	const config = useAppConfig()
	const popups = usePopups()
	const loggedInUser = useLoggedInUser()
	const { runAction } = useLoading()

	function formatDate(instant: Instant) {
		return instant.atZone(ZoneId.SYSTEM).toLocalDate().toGBDateString()
	}

	const canCancel = computed(() => canEditDocuments({ loggedInUser, tfs: store.tfs, shipment: props.shipment }))

	async function handleCancel() {
		const confirmation = await popups.confirm({
			content: () => (
				<Alert title="Confirm Cancel Shipment">
					<p>
						Are you <strong>certain</strong> you wish to cancel this shipment (no. {shipment.shipmentNumber})?
					</p>
					<p>
						{config.officeName} will ignore the shipment, but you{" "}
						<strong>
							<em>will not</em>
						</strong>{" "}
						get your shipment slot back.
					</p>
					<p>
						You will no longer be able to edit any details or upload any <em>Notification of Shipments</em> or{" "}
						<em>Recovery At Facility</em> documents to the shipment.
					</p>
				</Alert>
			),
			width: "32rem",
			cancelText: "No, Don't Cancel",
			confirmText: "Yes, Cancel",
		})
		if (!confirmation) {
			return
		}
		await runAction(store.cancelShipment(shipment))

		void popups.showAlertPopup(() => <Alert title="Success">The shipment has been successfully cancelled.</Alert>)
	}

	return () => {
		if (shipment.cancelled) {
			const by = shipment.cancelled.user
			let title = `Cancelled ${formatDate(shipment.cancelled.timeStamp)}`
			if (by) {
				title += ` by ${by.firstName} ${by.lastName}`
			}
			return (
				<td title={title} style={{ textDecoration: "none" }}>
					{formatDate(shipment.cancelled.timeStamp)}
				</td>
			)
		} else if (canCancel.value) {
			return (
				<td class="flex justify-between">
					<span>No</span>
					<BootstrapButton
						color="danger"
						title="Cancel Shipment"
						onClick={() => void handleCancel()}
						disabled={!canCancel.value}
					>
						<Icon icon={faTrashAlt} />
					</BootstrapButton>
				</td>
			)
		}
		return <td>No</td>
	}
}

export default defineComponent(CancelShipmentColumn, {
	shipment: requiredProp(Object),
})
