import TableControls from "@/components/table/TableControls"
import { computed, unref, type FunctionalComponent, type MaybeRef } from "vue"
import { RefQueries, paged, refSetter, useQueryRef } from "vue-utils"

export function useTablePaging<T>(items: MaybeRef<T[]>, pageSize = 12) {
	const page = useQueryRef("page", 1, RefQueries.Int)
	const pages = computed(() => Math.ceil(unref(items).length / pageSize))
	const pageItems = computed(() => paged(page.value, pageSize, unref(items)))

	const Controls: FunctionalComponent<{ entityName: string }> = ({ entityName }) => {
		const totalItems = unref(items).length
		return (
			<TableControls
				totalItems={totalItems}
				entityName={entityName}
				page={page.value}
				pageSize={pageSize}
				setPage={refSetter(page)}
				totalPages={Math.ceil(totalItems / pageSize)}
			/>
		)
	}

	return {
		page,
		pages,
		pageItems,
		PagingControls: Controls,
	}
}
