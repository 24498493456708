export enum TFSStatus {
	Active = "Active",
	Pending = "Pending",
	Future = "Future",
	Expired = "Expired",
	Closed = "Closed",
}

const statusColors: Record<TFSStatus, string> = {
	[TFSStatus.Active]: "#d5ffc1",
	[TFSStatus.Pending]: "#d3f5ff",
	[TFSStatus.Future]: "#ffffff",
	[TFSStatus.Expired]: "#ffe4b5",
	[TFSStatus.Closed]: "#ffd9d9",
}

export function getTFSStatusColor(status: TFSStatus) {
	return statusColors[status]
}
