import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import { useLoading } from "@/composition/useLoading"
import type { ShipmentLog } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import usePopups from "@/stores/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons"
import { ZoneId, type Instant } from "@js-joda/core"
import { computed } from "vue"
import { Alert, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { canEditDocuments } from "../permissions"
import { useShipmentsStore } from "../store"

interface Props {
	shipment: ShipmentLog
}

const CompleteShipmentColumn: ReactiveComponent<Props> = (props) => {
	const shipment = proxyProp(props, "shipment")
	const store = useShipmentsStore()
	const popups = usePopups()
	const loggedInUser = useLoggedInUser()
	const { runAction } = useLoading()

	function formatDate(instant: Instant) {
		return instant.atZone(ZoneId.SYSTEM).toLocalDate().toGBDateString()
	}

	const canEdit = computed(() => canEditDocuments({ loggedInUser, tfs: store.tfs, shipment: props.shipment }))
	const hasRecoveryAtFacility = computed(
		() => canEdit.value && !!shipment.recoveryAtFacility && shipment.recoveryAtFacility.length > 0
	)

	async function handleClose() {
		if (!canEdit.value) {
			return
		}
		if (!hasRecoveryAtFacility.value) {
			void popups.showAlertPopup(() => (
				<Alert title="Missing Documents">
					In order to close this shipment, please upload all relevant <strong>Recovery at Facility</strong>{" "}
					documentation.
				</Alert>
			))
			return
		}

		const confirmation = await popups.confirm({
			content: () => (
				<Alert title="Confirm Complete Shipment">
					<p>Are you sure you wish to complete this shipment (no. {shipment.shipmentNumber})?</p>
					<p>
						You will no longer be able to edit any details or upload any <em>Notification of Shipments</em> or{" "}
						<em>Recovery At Facility</em> documents to the shipment, and it will be marked as complete.
					</p>
				</Alert>
			),
			width: "32rem",
			confirmText: "Complete Shipment",
		})
		if (!confirmation) {
			return
		}
		await runAction(store.completeShipment(shipment))

		void popups.showAlertPopup(() => <Alert title="Success">The shipment has been successfully completed.</Alert>)
	}

	return () => {
		if (shipment.completed) {
			const by = shipment.completed.user
			let title = `Completed ${formatDate(shipment.completed.timeStamp)}`
			if (by) {
				title += ` by ${by.firstName} ${by.lastName}`
			}
			return <td title={title}>{formatDate(shipment.completed.timeStamp)}</td>
		} else if (canEdit.value && hasRecoveryAtFacility.value) {
			return (
				<td class="flex justify-between">
					<span>No</span>
					<BootstrapButton color="dark" title="Complete Shipment" onClick={() => void handleClose()}>
						<Icon icon={faClipboardCheck} />
					</BootstrapButton>
				</td>
			)
		} else if (canEdit.value && !hasRecoveryAtFacility.value) {
			return (
				<td class="flex justify-between">
					<span>No</span>
					<BootstrapButton
						color="dark"
						title="Unable to complete until Recovery At Facility has been uploaded"
						style={{ pointerEvents: "initial", cursor: "not-allowed" }}
						onClick={() => void handleClose()}
					>
						<Icon icon={faClipboardCheck} />
					</BootstrapButton>
				</td>
			)
		} else {
			return <td>No</td>
		}
	}
}

export default defineComponent(CompleteShipmentColumn, {
	shipment: requiredProp(Object),
})
