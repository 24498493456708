import type { Company, Id, User } from "@/models"
import { computed, unref, type ComputedRef, type MaybeRef } from "vue"

export function useUsersFiltering(options: {
	users: MaybeRef<User[]>
	companies: MaybeRef<Map<Id, Company>>
	searchTerm: MaybeRef<string>
}): ComputedRef<User[]> {
	const { companies, searchTerm: search, users } = options

	return computed(() => {
		const usersArray = unref(users)
		const searchTerm = unref(search).toLowerCase()
		if (searchTerm.length === 0) {
			return usersArray
		}

		const companiesById = unref(companies)

		return unref(users).filter((user) => {
			const name = `${user.firstName} ${user.lastName}`.toLowerCase()
			const companyName = companiesById.get(user.companyId ?? NaN)?.name?.toLowerCase()

			return name.includes(searchTerm) || (companyName && companyName.includes(searchTerm))
		})
	})
}
