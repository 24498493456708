import BasicForm, { basicFormButton, type FormButton } from "@/components/form/BasicForm"
import BootstrapButton from "@/components/form/BootstrapButton"
import type { ShipmentLog } from "@/models"
import DocumentsFileUpload from "@/shared/DocumentsFileUpload"
import usePopups from "@/stores/popupsStore"
import { computed, ref, shallowRef } from "vue"
import {
	Alert,
	defineComponent,
	ModalTitle,
	refSetter,
	Required,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"
import type { ShipmentDetails } from "../ManageShipments"

interface Props {
	createShipment(shipmentNumber: number, files: File[]): Promise<ShipmentLog>
	cancel(): void
	shipmentDetails: ShipmentDetails
}

const CreateShipmentForm: ReactiveComponent<Props> = (props) => {
	const files = shallowRef<File[]>([])
	const shipmentNo = ref(props.shipmentDetails.shipmentNumber)
	const popups = usePopups()

	const shipmentNoRef = shallowRef<HTMLInputElement>()

	async function validateForm() {
		return await popups.confirm(() => (
			<Alert title="Confirm Create Shipment">
				Please confirm you have uploaded <i>all</i> corresponding documentation. You will be using 1 of your shipment
				slots.
			</Alert>
		))
	}

	async function handleCreate() {
		const createdShipment = await props.createShipment(shipmentNo.value, files.value)
		void popups.showAlertPopup(() => (
			<Alert title="Successfully Created Shipment">
				A new shipment was successfully created. The new shipment number is{" "}
				<strong>{createdShipment.shipmentNumber}</strong>
			</Alert>
		))
	}

	const buttons = computed<FormButton[]>(() => {
		const cancel = basicFormButton({
			color: "secondary",
			id: "cancel",
			text: "Cancel",
			validateForm: false,
			onSubmit: props.cancel,
		})
		const submit: FormButton = {
			id: "submit",
			render: (btnProps) => (
				<BootstrapButton color="primary" {...btnProps} disabled={btnProps.disabled || files.value.length === 0}>
					Create
				</BootstrapButton>
			),
			validateForm: false,
			validate: validateForm,
			onSubmit: handleCreate,
		}
		return [cancel, submit]
	})

	return () => (
		<>
			<ModalTitle title="Create Shipment" />
			<hr />
			<BasicForm buttons={buttons.value}>
				<div class="flex spacing-4 mb-3">
					<Required label="Shipment Number" />
					<input
						ref={shipmentNoRef}
						type="number"
						min={props.shipmentDetails.shipmentNumber}
						v-model={shipmentNo.value}
					/>
				</div>
				<p>
					To create a shipment, you must first upload all documents related to the{" "}
					<strong>notification of shipment</strong>.
				</p>
				<DocumentsFileUpload setFiles={refSetter(files)} validationOptions={{ allowNoFiles: false }} />
			</BasicForm>
		</>
	)
}

export default defineComponent(CreateShipmentForm, {
	cancel: requiredProp(Function),
	createShipment: requiredProp(Function),
	shipmentDetails: requiredProp(Object),
})
