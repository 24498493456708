import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import SearchBar from "@/components/SearchBar"
import BootstrapButton from "@/components/form/BootstrapButton"
import ColumnTable from "@/components/table/ColumnTable"
import { useTablePaging } from "@/composition/useTablePaging"
import { type User } from "@/models"
import { useCompanies } from "@/stores/companiesStore"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { formatEnum } from "@/utils/formatEnum"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed, reactive, ref, shallowRef, toRef } from "vue"
import { ModalTitle, debounce, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import UserForm from "./UserForm"
import { useUsersFiltering } from "./filtering"
import { updateUser } from "./requests"

interface Props {
	users: User[]
}

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

const UsersTable: ReactiveComponent<Props> = (props) => {
	const searchTerm = ref<string>("")
	const companies = useCompanies()
	const loggedInUser = useLoggedInUser()
	const editingUser = shallowRef<User | null>(null)

	const filteredUsers = useUsersFiltering({
		companies,
		users: toRef(props, "users"),
		searchTerm,
	})
	const sortedUsers = computed(() =>
		[...filteredUsers.value].sort((u1, u2) => {
			const name1 = `${u1.firstName} ${u2.lastName}`
			const name2 = `${u1.lastName} ${u2.lastName}`
			return name1.localeCompare(name2)
		})
	)

	const { page, pageItems, PagingControls } = useTablePaging(sortedUsers)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	async function saveUser() {
		const updatedUser = await updateUser(editingUser.value!)
		for (const user of props.users) {
			if (user.id === updatedUser.id) {
				Object.assign(user, updatedUser)
			}
		}

		if (loggedInUser.id === updatedUser.id) {
			Object.assign(loggedInUser, updatedUser)
		}

		editingUser.value = null
	}

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			{!!editingUser.value && (
				<Modal style={{ width: "56rem" }}>
					<ModalTitle title="Edit User" />
					<hr />
					<UserForm user={editingUser.value} cancel={() => (editingUser.value = null)} save={saveUser} />
				</Modal>
			)}

			<ColumnTable
				getKey={(user) => user.id}
				class={["mt-3", tableStyles]}
				entities={pageItems.value}
				columns={{
					name: {
						label: "Name",
						size: "auto",
						renderContent: ({ item: user }) => `${user.firstName} ${user.lastName}`,
					},
					email: {
						label: "Email",
						size: "auto",
						renderContent: ({ item: user }) => user.emailAddress,
					},
					role: {
						label: "Role",
						size: "auto",
						renderContent: ({ item: user }) => formatEnum(user.role),
					},
					company: {
						label: "Company",
						size: "auto",
						renderContent: ({ item: user }) => companies.get(user.companyId ?? NaN)?.name ?? "-",
					},
					loginEnabled: {
						label: "Login Enabled?",
						size: "max-content",
						renderContent: ({ item: user }) => (user.loginEnabled ? "Yes" : "No"),
					},
					controls: {
						label: "",
						size: "max-content",
						renderContent: ({ item: user }) => (
							<BootstrapButton color="secondary" onClick={() => (editingUser.value = reactive({ ...user }))}>
								<Icon icon={faEdit} />
								<span>Edit</span>
							</BootstrapButton>
						),
					},
				}}
			/>

			<PagingControls entityName="Users" />
		</>
	)
}

export default defineComponent(UsersTable, {
	users: requiredProp(Array),
})
