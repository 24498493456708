import SimpleForm from "@/components/form/SimpleForm"
import DocumentsFileUpload from "@/shared/DocumentsFileUpload"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { TFSFormSection, useTFSFormContext } from "../context"

const ConsentingDocuments: ReactiveComponent = () => {
	const context = useTFSFormContext()

	return () => (
		<SimpleForm
			cancel={{
				text: "Back",
				onClick: () => context.changeSection(TFSFormSection.Main),
			}}
			submit={{
				text: `${context.type === "create" ? "Create" : "Save"} TFS`,
				onClick: () => context.save(),
				validate: context.validation.validateSave,
			}}
			style={{ display: "contents" }}
		>
			<div class="flex flex-col flex-grow-1 overflow-auto" style={{ padding: "0.25rem" }}>
				<p>Please upload all documentation relevant to the issuing of consent for this TFS.</p>

				<DocumentsFileUpload
					initialFiles={context.formData.consentIssuedDocuments}
					setFiles={(files) => (context.formData.consentIssuedDocuments = files)}
					validationOptions={{ allowNoFiles: true }}
					class="flex-grow-1"
				/>
			</div>
		</SimpleForm>
	)
}

export default defineComponent(ConsentingDocuments)
