import BasicForm, { basicFormButton, type FormButton } from "@/components/form/BasicForm"
import BootstrapButton from "@/components/form/BootstrapButton"
import type { ShipmentLogDocumentKeys } from "@/models"
import DocumentsFileUpload from "@/shared/DocumentsFileUpload"
import usePopups from "@/stores/popupsStore"
import { shallowRef } from "vue"
import { Alert, ModalTitle, defineComponent, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	type: ShipmentLogDocumentKeys
	hasExisting: boolean

	uploadDocuments(files: File[]): Promise<void>
	cancel(): void
}

const UploadDocumentsForm: ReactiveComponent<Props> = (props) => {
	const files = shallowRef<File[]>([])
	const popups = usePopups()

	async function validateForm() {
		return await popups.confirm(() => (
			<Alert title="Confirm File Upload">
				Please confirm that the documents you have selected to upload are correct. You will not be able to delete them
				later.
			</Alert>
		))
	}

	async function handleSubmit() {
		await props.uploadDocuments(files.value)
		void popups.showAlertPopup(() => (
			<Alert title="Success!">
				<p>
					The attached documents have been successfully uploaded.
					{props.type === "recoveryAtFacility" && (
						<>
							<br />
							To complete this shipment, you must now click on the Completed button.
						</>
					)}
				</p>
			</Alert>
		))
	}

	const submitButton: FormButton = {
		id: "submit",
		render: (btnProps) => (
			<BootstrapButton color="primary" {...btnProps} disabled={btnProps.disabled || files.value.length === 0}>
				{props.type === "notificationOfShipments" && !props.hasExisting ? "Create" : "Upload"}
			</BootstrapButton>
		),
		validateForm: false,
		validate: validateForm,
		onSubmit: handleSubmit,
	}

	const cancelButton = basicFormButton({
		color: "secondary",
		id: "cancel",
		text: "Cancel",
		validateForm: false,
		onSubmit: props.cancel,
	})

	return () => (
		<BasicForm buttons={[cancelButton, submitButton]} class="flex flex-col h-full">
			<ModalTitle title="Upload Documents" />
			<hr />
			<DocumentsFileUpload
				setFiles={refSetter(files)}
				class="flex-grow-1"
				validationOptions={{ allowNoFiles: false }}
			/>
		</BasicForm>
	)
}

export default defineComponent(UploadDocumentsForm, {
	type: requiredProp(String),
	hasExisting: requiredProp(Boolean),

	uploadDocuments: requiredProp(Function),
	cancel: requiredProp(Function),
})
