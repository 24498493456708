import backgroundImg from "@/assets/background.webp"
import FrostedGlass from "@/components/FrostedGlass"
import PageBackground from "@/components/PageBackground"
import { useAppConfig } from "@/stores/configStore"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import LoginButton from "./LoginButton"

const contentStyles = css`
	display: flex;
	flex-direction: column;
	width: 34rem;

	font-size: 1.125rem;
	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		margin: 0;
		color: #444;
	}
	hr {
		border-color: black;
	}
	button {
		margin-top: 2rem;
		width: 100%;
		font-size: 1.125rem;
	}
`

const LoginPage: ReactiveComponent = () => {
	const config = useAppConfig()

	return () => (
		<BasicPage title={`Guernsey ${config.appName}`} plain noPadding noNav>
			<PageBackground imageUrl={backgroundImg}>
				<FrostedGlass class={contentStyles}>
					<h1>Guernsey {config.appName}</h1>
					<hr />
					<p>Welcome to the waste shipping platform.</p>
					<p>Please click the button below to log in or create an account.</p>
					<div class="flex justify-center">
						<LoginButton />
					</div>
				</FrostedGlass>
			</PageBackground>
		</BasicPage>
	)
}

export default defineComponent(LoginPage)
