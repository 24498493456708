import BasicForm from "@/components/form/BasicForm"
import ExistingDocuments from "@/shared/ExistingDocuments"
import { computed, h, ref } from "vue"
import { FormTabs, defineComponent, refSetter, type FormTab, type ReactiveComponent } from "vue-utils"
import { useTFSFormContext } from "../context"
import AdditionalDocuments from "./AdditionalDocuments"
import TFSDetails from "./TFSDetails"
import { useTFSFormButtons } from "./buttons"

const TFSMainDetails: ReactiveComponent = () => {
	const context = useTFSFormContext()
	const buttons = useTFSFormButtons()

	const tabId = ref("details")
	const tabs = computed<FormTab[]>(() => {
		const tabs: FormTab[] = []
		tabs.push({
			id: "details",
			name: "Details",
			renderContent: () => (
				<BasicForm buttons={buttons.value} style={{ display: "contents" }}>
					<div class="flex flex-col flex-grow-1 overflow-auto" style={{ padding: "0.25rem" }}>
						<TFSDetails />
					</div>
				</BasicForm>
			),
		})

		if (context.existingTFS && context.existingTFS.consentIssued) {
			tabs.push({
				id: "consentIssuedDocuments",
				name: "Consenting Documents",
				renderContent: () => (
					<ExistingDocuments name="consent issued" documents={context.existingTFS?.consentIssuedDocuments ?? []} />
				),
			})
		}

		if (context.existingTFS && context.existingTFS.additionalDocuments) {
			tabs.push({
				id: "additionalDocuments",
				name: "Additional Documents",
				renderContent: AdditionalDocuments,
			})
		}

		if (context.existingTFS && context.existingTFS.closed) {
			tabs.push({
				id: "closedDocuments",
				name: "Closing Documents",
				renderContent: () => (
					<ExistingDocuments name="closing" documents={context.existingTFS?.closedDocuments ?? []} />
				),
			})
		}

		return tabs
	})

	return () =>
		tabs.value.length > 1 ? (
			<FormTabs tabs={tabs.value} selectedTabId={tabId.value} setSelectedTab={refSetter(tabId)} />
		) : (
			h(tabs.value[0].renderContent!)
		)
}

export default defineComponent(TFSMainDetails)
