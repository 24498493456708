import {
	SystemRole,
	type LoggedInUser,
	type ShipmentLog,
	type ShipmentLogDocumentKeys,
	type TFSEntry,
	type TFSEntryMetadata,
} from "@/models"
import { isTFSActive } from "../tfs-entries/permissions"

export function canShipmentsBeManaged(tfs: TFSEntryMetadata | TFSEntry): boolean {
	return isTFSActive(tfs) && !!tfs.consentIssued && !tfs.closed
}

export function allowedToManageShipments(loggedInUser: LoggedInUser, tfs: TFSEntryMetadata | TFSEntry): boolean {
	return loggedInUser.atLeastHasRole(SystemRole.User) && loggedInUser.canViewCompany(tfs.companyId)
}

export function canManageShipments(loggedInUser: LoggedInUser, tfs: TFSEntryMetadata | TFSEntry): boolean {
	return canShipmentsBeManaged(tfs) && allowedToManageShipments(loggedInUser, tfs)
}

export function canCreateShipment(loggedInUser: LoggedInUser, tfs: TFSEntryMetadata): boolean {
	if (!canManageShipments(loggedInUser, tfs)) {
		return false
	}
	if (tfs.maximumShipments === null || tfs.maximumActiveShipments === null) {
		return false
	}
	if (tfs.totalShipments >= tfs.maximumShipments || tfs.activeShipments >= tfs.maximumActiveShipments) {
		return false
	}
	return true
}

export function canEditDocuments(options: {
	loggedInUser: LoggedInUser
	tfs: TFSEntryMetadata
	shipment: ShipmentLog
	type?: ShipmentLogDocumentKeys
}): boolean {
	const { loggedInUser, tfs, shipment, type } = options

	if (type === "additionalDocuments") {
		if (tfs.closed) return loggedInUser.atLeastHasRole(SystemRole.Administrator)
		else return allowedToManageShipments(loggedInUser, tfs)
	}

	if (!canManageShipments(loggedInUser, tfs)) {
		return false
	}

	if (shipment.cancelled || shipment.completed) {
		return false
	}

	return true
}
