import { SystemRole, type LoggedInUser, type TFSEntry, type TFSEntryMetadata } from "@/models"
import { LocalDate } from "@js-joda/core"

export function isTFSActive(tfs: TFSEntryMetadata | TFSEntry) {
	const now = LocalDate.now()
	if (tfs.closed) {
		return false
	}
	if (tfs.validFrom && now.compareTo(tfs.validFrom) < 0) {
		return false
	}
	if (tfs.validTo && now.compareTo(tfs.validTo) > 0) {
		return false
	}
	return true
}

export function canEditAnyTFSField(tfs: TFSEntryMetadata | TFSEntry | null, loggedInUser: LoggedInUser) {
	if (!loggedInUser.atLeastHasRole(SystemRole.Administrator)) {
		return false
	}
	if (!tfs) {
		return true
	}
	if (tfs.closed) {
		return false
	}
	if (tfs.consentIssued !== null && tfs.validTo && LocalDate.now().isAfter(tfs.validTo)) {
		return false
	}
	return true
}
