import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import BootstrapButton from "@/components/form/BootstrapButton"
import { StateLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/models"
import { useAtLeastHasRole } from "@/stores/loggedInUserStore"
import { faCirclePlus, faFilter } from "@fortawesome/free-solid-svg-icons"
import { onBeforeMount } from "vue"
import { RefQueries, defineComponent, useQueryStoreRef, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import TFSResultsDisplay from "./TFSResultsDisplay"
import TFSFilters from "./components/TFSFilters"
import TFSEntryForm from "./editing-tfs/TFSEntryForm"
import { useTFSEntriesStore } from "./store"
import { bindTFSRequestToQuery } from "./useTFSSearch"

const TFSEntriesPage: ReactiveComponent = () => {
	const canCreateTFS = useAtLeastHasRole(SystemRole.Administrator)
	const store = useTFSEntriesStore()

	//Bind request options only in component to ensure it gets cleaned up after unmount rather than persisted
	bindTFSRequestToQuery(store.requestOptions)
	useQueryStoreRef("tfs", store, "editingTFSId", RefQueries.NullableInt)

	onBeforeMount(() => void store.refreshResults())

	return () => (
		<BasicPage title="Transfrontier Shipments">
			<h5 class="mb-1 flex items-center flex-wrap spacing-2">
				<span class="flex-grow-1">Filter Shipments</span>
				{canCreateTFS.value && (
					<BootstrapButton color="success" onClick={store.openNewTFS}>
						<Icon icon={faCirclePlus} />
						<span>Create TFS</span>
					</BootstrapButton>
				)}
				<BootstrapButton color="danger" onClick={store.resetOptions}>
					<Icon icon={faFilter} />
					<span>Reset Filters</span>
				</BootstrapButton>
			</h5>

			<TFSFilters />

			{(store.creatingTFS || store.editingTFS !== null) && (
				<Modal style={{ width: "64rem", height: "36rem" }} class="flex flex-col">
					<TFSEntryForm existingTFS={store.creatingTFS ? null : store.editingTFS} />
				</Modal>
			)}

			<hr />

			<StateLoading stores={[store.tfsEntriesRef]}></StateLoading>
			{store.hasResults() && <TFSResultsDisplay />}
		</BasicPage>
	)
}

export default defineComponent(TFSEntriesPage)
