import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/models"
import { HttpLoadingError } from "@/services/httpHelper"
import { useAtLeastHasRole } from "@/stores/loggedInUserStore"
import { computed } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, useLoadableRef, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import { getTFSEntryMetadata } from "../tfs-entries/requests"
import ManageShipments from "./ManageShipments"

const ManageShipmentsPage: ReactiveComponent = () => {
	const router = useRouter()
	const isAdmin = useAtLeastHasRole(SystemRole.Administrator)
	const tfsId = computed(() => router.currentRoute.value.params.tfsId as string)
	const tfsEntryRef = useLoadableRef(loadTfsEntry)

	async function loadTfsEntry() {
		const id = Number.parseInt(tfsId.value)
		if (!Number.isSafeInteger(id) || id <= 0) {
			return null
		}
		try {
			return await getTFSEntryMetadata(id)
		} catch (e) {
			if (e instanceof HttpLoadingError && e.statusCode === 404) {
				return null
			}
			throw e
		}
	}

	function renderContent() {
		if (tfsEntryRef.type !== "done") {
			return null
		}
		const tfs = tfsEntryRef.result
		if (!tfs) {
			return "The TFS entry you requested could not be found."
		}
		return <ManageShipments tfs={tfs} />
	}

	function getTitle() {
		if (tfsEntryRef.type !== "done") {
			return "Manage Shipments"
		}
		const tfs = tfsEntryRef.result
		if (!tfs) {
			return "Unknown TFS"
		}
		if (isAdmin.value) {
			return `Manage Shipments (${tfs.companyName})`
		}
		return "Manage Shipments"
	}

	return () => (
		<BasicPage title={getTitle()}>
			<ContentLoading stores={[tfsEntryRef]}>{renderContent()}</ContentLoading>
		</BasicPage>
	)
}

export default defineComponent(ManageShipmentsPage)
