import type { DocumentUpload } from "@/models"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import ExistingDocumentRow from "./ExistingDocumentRow"
import { computed } from "vue"

interface Props {
	name: string
	documents: DocumentUpload[]
}

const containerStyles = css`
	flex-shrink: 1;
	overflow: auto;

	background-color: rgba(0, 0, 0, 0.05);
	padding: 0.125rem 0.5rem 0.125rem 0.25rem;
	border: thin solid rgba(0, 0, 0, 0.35);
	border-radius: 0.25rem;
`

const tableStyles = css`
	table-layout: auto;
	border-collapse: collapse;
	width: 100%;

	grid-template-columns: minmax(1em, 1fr) auto auto max-content max-content;

	tr:last-child {
		td,
		th {
			border-bottom: none;
		}
	}
`

const ExistingDocuments: ReactiveComponent<Props> = (props) => {
	const sortedDocs = computed(() =>
		[...props.documents].sort((d1, d2) => -d1.uploadedBy.timeStamp.compareTo(d2.uploadedBy.timeStamp))
	)

	return () =>
		props.documents.length === 0 ? (
			<p class="m-0">No {props.name} documents have been uploaded.</p>
		) : (
			<div class={containerStyles}>
				<table class={["grid-table", tableStyles]}>
					<tbody>
						{sortedDocs.value.map((document) => (
							<ExistingDocumentRow key={document.guid} document={document} />
						))}
					</tbody>
				</table>
			</div>
		)
}

export default defineComponent(ExistingDocuments, {
	name: requiredProp(String),
	documents: requiredProp(Array),
})
