import SimpleForm from "@/components/form/SimpleForm"
import type { DocumentUpload } from "@/models"
import DocumentsFileUpload from "@/shared/DocumentsFileUpload"
import usePopups from "@/stores/popupsStore"
import { shallowRef } from "vue"
import { Alert, ModalTitle, defineComponent, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import { useTFSFormContext } from "../context"

interface Props {
	cancel(): void
	onUploaded(newDocs: DocumentUpload[]): void
}

const UploadAdditionalDocs: ReactiveComponent<Props> = (props) => {
	const uploadingDocs = shallowRef<File[]>([])
	const context = useTFSFormContext()
	const popups = usePopups()

	async function validateForm() {
		return await popups.confirm(() => (
			<Alert title="Confirm File Upload">
				Please confirm that the documents you have selected to upload are correct. You will not be able to delete them
				later.
			</Alert>
		))
	}

	async function submit() {
		const newDocs = await context.uploadAdditionalDocuments(uploadingDocs.value)
		props.onUploaded(newDocs)
	}

	return () => (
		<>
			<ModalTitle title="Upload Additional Documents" />
			<hr />
			<SimpleForm
				class="display-contents"
				submit={{
					text: "Upload",
					onClick: submit,
					validate: validateForm,
					buttonProps: { disabled: uploadingDocs.value.length === 0 },
				}}
				cancel={props.cancel}
			>
				<DocumentsFileUpload
					class="flex-grow-1"
					setFiles={refSetter(uploadingDocs)}
					validationOptions={{ allowNoFiles: true }}
				/>
			</SimpleForm>
		</>
	)
}

export default defineComponent(UploadAdditionalDocs, {
	cancel: requiredProp(Function),
	onUploaded: requiredProp(Function),
})
