import { type TFSEntryMetadata } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { AutoColumns, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	tfs: TFSEntryMetadata
}

const TFSInfo: ReactiveComponent<Props> = (props) => {
	const tfs = proxyProp(props, "tfs")

	return () => (
		<>
			<h5>TFS Details</h5>
			<div class="flex flex-col spacing-4">
				<AutoColumns size="12.5rem" class="spacing-4">
					<label>
						TFS Number
						<input type="text" value={tfs.tfsNumber} disabled />
					</label>
					<label>
						WK Number
						<input type="text" value={tfs.wkNumber} disabled />
					</label>
					<label>
						Status
						<input type="text" value={formatEnum(tfs.status)} disabled />
					</label>
					<label>
						Valid From
						<input type="date" value={tfs.validFrom?.toHTMLInput()} disabled />
					</label>
					<label>
						Valid To
						<input type="date" value={tfs.validTo?.toHTMLInput()} disabled />
					</label>
					<label>
						Used Shipments
						<input type="text" value={`${tfs.totalShipments} / ${tfs.maximumShipments}`} disabled />
					</label>
					<label>
						Active Shipments
						<input type="text" value={`${tfs.activeShipments} / ${tfs.maximumActiveShipments}`} disabled />
					</label>
				</AutoColumns>
			</div>
		</>
	)
}

export default defineComponent(TFSInfo, {
	tfs: requiredProp(Object),
})
