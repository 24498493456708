import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import BootstrapButton from "@/components/form/BootstrapButton"
import ColumnTable from "@/components/table/ColumnTable"
import { useTablePaging } from "@/composition/useTablePaging"
import type { Company } from "@/models"
import { useCompaniesStore } from "@/stores/companiesStore"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed, reactive, ref, shallowRef, toRef } from "vue"
import { debounce, defineComponent, type ReactiveComponent } from "vue-utils"
import CompanyForm from "./CompanyForm"
import CompanyModal from "./CompanyModal"
import { useCompaniesFiltering } from "./filtering"

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

function createBlankCompany(): Company {
	return {
		id: 0,
		name: "",
		address: "",
		email: "",
		mainContact: "",
		postcode: "",
		telephoneNumber: "",
	}
}

const CompaniesTable: ReactiveComponent = () => {
	const store = useCompaniesStore()

	const searchTerm = ref<string>("")

	const creatingCompany = shallowRef<Company | null>(null)
	const editingCompany = shallowRef<Company | null>(null)

	const filteredCompanies = useCompaniesFiltering({
		companies: toRef(() => Array.from(store.getCompanies().values())),
		searchTerm,
	})

	const sortedCompanies = computed(() => [...filteredCompanies.value].sort((c1, c2) => c1.name.localeCompare(c2.name)))

	const { page, pageItems, PagingControls } = useTablePaging(sortedCompanies)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	async function handleCreateCompany() {
		await store.createCompany(creatingCompany.value!)
		creatingCompany.value = null
	}

	async function handleEditCompany() {
		await store.updateCompany(editingCompany.value!)
		editingCompany.value = null
	}

	async function handleDeleteCompany() {
		await store.deleteCompany(editingCompany.value!.id)
		editingCompany.value = null
	}

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			{!!creatingCompany.value && (
				<CompanyModal type="create">
					<CompanyForm
						company={creatingCompany.value}
						type="create"
						cancel={() => (creatingCompany.value = null)}
						save={handleCreateCompany}
					/>
				</CompanyModal>
			)}

			{!!editingCompany.value && (
				<CompanyModal type="edit">
					<CompanyForm
						company={editingCompany.value}
						type="edit"
						cancel={() => (editingCompany.value = null)}
						save={handleEditCompany}
						delete={handleDeleteCompany}
					/>
				</CompanyModal>
			)}

			<ColumnTable
				getKey={(company) => company.id}
				class={["mt-3", tableStyles]}
				entities={pageItems.value}
				columns={{
					name: {
						label: "Name",
						size: "1fr",
						renderContent: ({ item }) => item.name,
					},
					mainContact: {
						label: "Main Contact",
						size: "0.75fr",
						renderContent: ({ item }) => item.mainContact,
					},
					email: {
						label: "Email",
						size: "0.75fr",
						renderContent: ({ item }) => item.email,
					},
					telephone: {
						label: "Telephone",
						size: "0.75fr",
						renderContent: ({ item }) => item.telephoneNumber,
					},
					controls: {
						label: "",
						size: "max-content",
						renderTh: () => (
							<th>
								<BootstrapButton color="success" onClick={() => (creatingCompany.value = createBlankCompany())}>
									Add New Company
								</BootstrapButton>
							</th>
						),
						renderContent: ({ item }) => (
							<BootstrapButton color="secondary" onClick={() => (editingCompany.value = reactive({ ...item }))}>
								<Icon icon={faEdit} />
								<span>Edit</span>
							</BootstrapButton>
						),
					},
				}}
			/>

			<PagingControls entityName="Companies" />
		</>
	)
}

export default defineComponent(CompaniesTable)
