import BasicSelect, { createSelect } from "@/components/form/BasicSelect"
import { createMultiSelect } from "@/components/multi-select"
import { SystemRole, TFSStatus, getTFSStatusColor } from "@/models"
import { useCompanies } from "@/stores/companiesStore"
import { useAtLeastHasRole } from "@/stores/loggedInUserStore"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { computed } from "vue"
import { AutoColumns, defineComponent, type ReactiveComponent } from "vue-utils"
import { useTFSEntriesStore } from "../store"

const StatusMultiSelect = createMultiSelect<TFSStatus>()

const TFSFilters: ReactiveComponent = () => {
	const companies = useCompanies()
	const store = useTFSEntriesStore()
	const filter = proxyProp(() => store.requestOptions.filter)
	const isAdmin = useAtLeastHasRole(SystemRole.Administrator)

	const NullableBooleanSelect = createSelect<boolean | null>()
	const shownStatuses = computed(() => {
		if (isAdmin.value) {
			return Object.values(TFSStatus)
		}

		//Regular users can't see pending (i.e. consent not issued) TFS's, so hide this option
		return Object.values(TFSStatus).filter((s) => s !== TFSStatus.Pending)
	})

	return () => (
		<AutoColumns size="12.5rem" class="spacing-4">
			<label>
				TFS Status
				<StatusMultiSelect
					options={shownStatuses.value}
					selected={filter.statuses}
					setSelected={(statuses) => (filter.statuses = statuses)}
					getKey={(s) => s}
					getText={formatEnum}
					getColor={getTFSStatusColor}
					entityNames="Statuses Selected"
					noneText="All Statuses"
					allText="All Statuses"
					allowSearch={false}
					someText={(s) => s.join(", ")}
					class="w-full"
				/>
			</label>
			{isAdmin.value && (
				// Regular company users can only see TFS entries which have the consent issued anyway
				<label>
					Consent Issued?
					<NullableBooleanSelect
						options={[true, false]}
						value={filter.consentIssued}
						setValue={(value) => (filter.consentIssued = value)}
						getText={(cc) => (cc ? "Yes" : "No")}
						getValue={String}
						defaultText="Either"
					/>
				</label>
			)}
			{isAdmin.value && (
				<label>
					Company
					<BasicSelect
						options={Array.from(companies.values())}
						value={filter.companyIds.length > 0 ? filter.companyIds[0] : null}
						setValue={(company) => (filter.companyIds = company ? [company.id] : [])}
						defaultText="All Companies"
					/>
				</label>
			)}
			<label>
				TFS Number
				<input type="text" placeholder="Filter TFS number..." v-model={filter.tfsNumber} />
			</label>
			{isAdmin.value && (
				<label>
					Work Number
					<input type="text" placeholder="Filter work number..." v-model={filter.wkNumber} />
				</label>
			)}
		</AutoColumns>
	)
}

export default defineComponent(TFSFilters)
