import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { computed, shallowRef, watch, type HTMLAttributes, type InputHTMLAttributes } from "vue"
import { anyProp, defineComponent, optionalProp, propsWithDefaults, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"
import BootstrapButton from "../form/BootstrapButton"
import FileIndicator from "./FileIndicator"
import "./FileUpload.scss"

interface Props {
	name?: string
	accept?: string[]
	capture?: InputHTMLAttributes["capture"]
	multiple?: InputHTMLAttributes["multiple"]
	initialFiles?: File[]
	onSelectionChanged?(files: File[]): void

	inputAttrs?: InputHTMLAttributes
}

const FileUpload: ReactiveComponent<Props, HTMLAttributes> = (initialProps, { attrs }) => {
	const props = propsWithDefaults(initialProps, {
		multiple: false,
		initialFiles: [],
	})

	const fileInputRef = shallowRef<HTMLInputElement>()

	const filesToUpload = shallowRef<File[]>(props.initialFiles)
	const draggingFiles = shallowRef<File[]>([])

	const draggingMimeTypes = shallowRef<string[]>([])

	watch(filesToUpload, (files) => props.onSelectionChanged?.(files))

	const errorMessage = computed(() => {
		const dragging = draggingFiles.value
		if (dragging.length > 1 && !props.multiple) {
			return "May only upload 1 file"
		}

		if (props.accept) {
			let typeError = ""

			draggingMimeTypes.value.forEach((type) => {
				if (props.accept!.indexOf(type) === -1) {
					typeError =
						dragging.length > 1
							? "One of more of the files you are trying to upload has a file type that is not allowed. Please select a different file(s)"
							: "This file type is not allowed. Please select a different file"
					return
				}
			})

			if (typeError !== "") return typeError
		}
		return null
	})

	function triggerUploadPicker() {
		fileInputRef.value?.click()
	}

	function changeFiles(files: FileList | null) {
		let validFiles: File[] = []
		if (files) validFiles = Array.from(files)

		if (props.accept) {
			validFiles.forEach((file) => {
				if (props.accept!.indexOf(file.type) === -1) validFiles.splice(validFiles.indexOf(file), 1)
			})
		}

		if (!props.multiple) {
			const first = validFiles[0]
			filesToUpload.value = first ? [first] : []
		} else if (validFiles) {
			const result: File[] = []
			const exists = (file: File) =>
				result.some((f) => f.name === file.name && f.size === file.size && f.lastModified === file.lastModified)

			filesToUpload.value.filter((f) => !exists(f)).forEach((f) => result.push(f))
			validFiles.filter((f) => !exists(f)).forEach((f) => result.push(f))

			filesToUpload.value = result
		}
		if (fileInputRef.value) {
			fileInputRef.value.files = files
		}
	}

	function handleDragEnd() {
		draggingFiles.value = []
		draggingMimeTypes.value = []
	}

	function handleDrag(event: DragEvent) {
		event.stopPropagation()
		event.preventDefault()
		if (event.dataTransfer) {
			const draggedItems = Array.from(event.dataTransfer.items)

			const fileItems = draggedItems.filter((item) => item.kind === "file").map((item) => item.getAsFile()!)

			draggedItems.forEach((item) => {
				if (draggingMimeTypes.value.indexOf(item.type) === -1) draggingMimeTypes.value.push(item.type)
			})

			draggingFiles.value = fileItems
		} else {
			handleDragEnd()
		}
	}

	function handleFileDrop(event: DragEvent) {
		event.preventDefault()
		handleDrag(event)

		if (errorMessage.value === null && (event.dataTransfer || event.dataTransfer!.items.length > 0)) {
			changeFiles(event.dataTransfer!.files)
		}
		handleDragEnd()
	}

	const renderSelect = () => (
		<>
			<Icon icon={faUpload} />
			<h3>Drag & drop a file here</h3>
			<span>
				or{" "}
				<button type="button" onClick={triggerUploadPicker}>
					pick a file to upload
				</button>
			</span>
		</>
	)
	const renderFiles = (files: File[]) => (
		<>
			<div>
				<ul>
					{files.map((file, i) => (
						<li key={file.name}>
							<FileIndicator
								file={file}
								remove={() => {
									const newFiles = [...filesToUpload.value]
									newFiles.splice(i, 1)
									filesToUpload.value = newFiles
								}}
							/>
						</li>
					))}
				</ul>
			</div>
			<span class="flex justify-end">
				<BootstrapButton color="success" onClick={triggerUploadPicker}>
					<Icon icon={faUpload} />
					{props.multiple ? "Add another file" : "Pick a different file"}
				</BootstrapButton>
			</span>
		</>
	)

	const renderDragging = () => (
		<>
			<Icon icon={faUpload} />
			<h3 style={{ marginBottom: "2.35rem" }}>
				{draggingFiles.value.length > 1 ? "Drop files to upload" : "Drop file to upload"}
			</h3>
		</>
	)

	const renderContent = () => {
		if (errorMessage.value) {
			return <h5>{errorMessage.value}</h5>
		}
		if (draggingFiles.value.length > 0) {
			return renderDragging()
		}
		if (filesToUpload.value && filesToUpload.value.length) {
			return renderFiles(Array.from(filesToUpload.value))
		}
		return renderSelect()
	}

	return () => (
		<div
			class="file-upload-picker"
			onDrop={handleFileDrop}
			onDragenter={handleDrag}
			onDragover={handleDrag}
			onDragleave={handleDragEnd}
			data-drag={(draggingFiles.value.length > 0).toString()}
			data-invalid={Boolean(errorMessage.value).toString()}
			data-with-file={(!!filesToUpload.value && filesToUpload.value.length > 0).toString()}
			{...attrs}
		>
			<div style={{ pointerEvents: draggingFiles.value.length > 0 ? "none" : "all" }}>{renderContent()}</div>
			<input
				ref={fileInputRef}
				name={props.name}
				type="file"
				style={{ display: "none" }}
				accept={props.accept ? props.accept.join(",") : undefined}
				capture={props.capture}
				multiple={props.multiple}
				onInput={(e) => changeFiles((e.target as HTMLInputElement).files)}
				{...props.inputAttrs}
			/>
		</div>
	)
}

export default defineComponent(FileUpload, {
	name: optionalProp(String),
	accept: optionalProp(Array),
	capture: anyProp(),
	multiple: optionalProp(Boolean),
	initialFiles: optionalProp(Array),
	onSelectionChanged: optionalProp(Function),
	inputAttrs: optionalProp(Object),
})
