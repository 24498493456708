import type { ApiTypeOf } from "./ApiType"
import { mapApiUserTimeStamp, type UserTimeStamp } from "./UserTimeStamp"

export interface DocumentUpload {
	fileName: string
	mimeType: string
	guid: string
	fileSize: number
	uploadedBy: UserTimeStamp
}

export function mapApiDocumentUpload(upload: ApiTypeOf<DocumentUpload>): DocumentUpload {
	return {
		...upload,
		uploadedBy: mapApiUserTimeStamp(upload.uploadedBy),
	}
}
