import FullScreenLoading from "@/components/loading/FullScreenLoading"
import usePopups from "@/stores/popupsStore"
import { Alert, LoadingError, createLoadingHook, type Component } from "vue-utils"

export const showErrorMessage = async (title: string, message: string) => {
	await usePopups().showAlertPopup(() => (
		<Alert title={title}>
			<p>{message}</p>
		</Alert>
	))
}

const { LoadingWrapper, useLoading } = createLoadingHook({
	loadingComponent: FullScreenLoading as Component,
	showError: showErrorMessage,
	coolInTimeMs: 100,
})

export { LoadingError, LoadingWrapper, useLoading }
