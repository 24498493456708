import Modal from "@/components/Modal"
import { ModalTitle, defineComponent, renderSlot, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	type: "edit" | "create"
}

const CompanyModal: ReactiveComponent<Props> = (props, { slots }) => {
	return () => (
		<Modal style={{ width: "56rem" }}>
			<ModalTitle title={props.type === "edit" ? "Edit Company" : "Create Company"} />
			<hr />
			{renderSlot(slots)}
		</Modal>
	)
}

export default defineComponent(CompanyModal, {
	type: requiredProp(String),
})
