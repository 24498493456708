import BasicSelect from "@/components/form/BasicSelect"
import SimpleForm from "@/components/form/SimpleForm"
import { SystemRole, getRolePriority, type User } from "@/models"
import { useCompanies } from "@/stores/companiesStore"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import {
	AutoColumns,
	Required,
	ToggleButton,
	defineComponent,
	requiredProp,
	useOnInput,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	user: User

	cancel(): void
	save(): Promise<void>
}

const UserForm: ReactiveComponent<Props> = (props) => {
	const user = proxyProp(props, "user")
	const loggedInUser = useLoggedInUser()
	const companies = useCompanies()

	return () => (
		<SimpleForm submit={{ onClick: props.save, text: "Save" }} cancel={props.cancel}>
			<div class="flex flex-col spacing-4">
				<AutoColumns size="16rem" class="spacing-4">
					<label>
						First Name
						<input type="text" value={user.firstName} disabled />
					</label>
					<label>
						Last Name
						<input type="text" value={user.lastName} disabled />
					</label>
					<label>
						Email
						<input type="email" value={user.emailAddress} disabled />
					</label>
				</AutoColumns>

				<AutoColumns size="16rem" class="spacing-4">
					<label>
						<Required label="Role" />
						<select
							value={user.role}
							onChange={useOnInput((value) => (user.role = value as SystemRole))}
							disabled={loggedInUser.id === user.id || getRolePriority(user.role) > getRolePriority(loggedInUser.role)}
						>
							{Object.values(SystemRole).map((role) => (
								<option key={role} value={role} disabled={!loggedInUser.atLeastHasRole(role)}>
									{formatEnum(role)}
								</option>
							))}
						</select>
					</label>

					<label>
						Associated Company
						<BasicSelect
							options={Array.from(companies.values())}
							value={user.companyId}
							setValue={(company) => (user.companyId = company?.id ?? null)}
							defaultText="No Company"
						/>
					</label>

					<label>
						Login Enabled?
						<ToggleButton
							toggled={user.loginEnabled}
							setToggled={(enabled) => (user.loginEnabled = enabled)}
							disabled={loggedInUser.id === user.id}
						/>
					</label>
				</AutoColumns>
			</div>
		</SimpleForm>
	)
}

export default defineComponent(UserForm, {
	user: requiredProp(Object),

	cancel: requiredProp(Function),
	save: requiredProp(Function),
})
