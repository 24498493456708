import Icon from "@/components/Icon"
import type { FormButton } from "@/components/form/BasicForm"
import BasicForm from "@/components/form/BasicForm"
import BootstrapButton from "@/components/form/BootstrapButton"
import DocumentsFileUpload from "@/shared/DocumentsFileUpload"
import usePopups from "@/stores/popupsStore"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { shallowRef } from "vue"
import { Alert, ModalTitle, defineComponent, refSetter, type ReactiveComponent } from "vue-utils"
import { useTFSFormContext } from "../context"
import { useTFSValidation } from "../validation"

const CloseTFSForm: ReactiveComponent = () => {
	const context = useTFSFormContext()
	const validation = useTFSValidation(context)

	const closingDocuments = shallowRef<File[]>([])
	const popups = usePopups()

	async function validateForm() {
		return await validation.confirmCloseTFS(closingDocuments.value)
	}

	async function closeTFS() {
		await context.closeTFS(closingDocuments.value)
		void popups.showAlertPopup(() => (
			<Alert title="Successfully closed TFS">
				<p>This TFS has now been closed.</p>
			</Alert>
		))
	}

	const buttons: FormButton[] = [
		{
			id: "cancel",
			validateForm: false,
			onSubmit() {
				context.showingClosePopup = false
			},
			render: (btnProps) => (
				<BootstrapButton color="secondary" {...btnProps}>
					Cancel
				</BootstrapButton>
			),
		},
		{
			id: "close",
			validate: validateForm,
			validateForm: true,
			onSubmit: closeTFS,
			render: (btnProps) => (
				<BootstrapButton color="success" {...btnProps}>
					Close TFS
					<Icon icon={faCheck} />
				</BootstrapButton>
			),
		},
	]

	return () => (
		<>
			<ModalTitle title="Close TFS" />
			<hr />
			<BasicForm class="display-contents" buttons={buttons}>
				<p>Please upload all documents pertaining to the closing of this TFS.</p>
				<DocumentsFileUpload
					class="flex-grow-1"
					setFiles={refSetter(closingDocuments)}
					validationOptions={{ allowNoFiles: true }}
				/>
			</BasicForm>
		</>
	)
}

export default defineComponent(CloseTFSForm)
