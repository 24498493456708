import BasicSelect from "@/components/form/BasicSelect"
import { useCompanies } from "@/stores/companiesStore"
import usePopups from "@/stores/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import { Duration, LocalDate } from "@js-joda/core"
import { computed, shallowRef, toRef, watch } from "vue"
import {
	Alert,
	AutoColumns,
	Required,
	ToggleButton,
	defineComponent,
	useInputValidation,
	useOnInput,
	usePollRef,
	type ReactiveComponent,
} from "vue-utils"
import { useTFSFormContext } from "../context"

const TFSDetails: ReactiveComponent = () => {
	const context = useTFSFormContext()
	const popups = usePopups()
	const companies = useCompanies()

	const formData = proxyProp(context, "formData")

	const now = usePollRef(Duration.ofMinutes(15).toMillis(), () => LocalDate.now())

	const canEdit = computed(() => context.canEdit)
	const isConsentIssued = computed(() => context.existingTFS !== null && context.existingTFS.consentIssued !== null)

	const dateToMin = computed(() => {
		if (formData.validFrom && formData.validFrom && formData.validFrom.compareTo(now.value) > 0) {
			return formData.validFrom.toHTMLInput()
		}
		return now.value.toHTMLInput()
	})

	async function handleConsentIssuedChange(issued: boolean) {
		if (!issued) {
			formData.consentIssued = false
			formData.consentIssuedDocuments = []
			return
		}

		const confirmation = await popups.confirm({
			content: () => (
				<Alert title="Confirm consent issued">
					<p>Please confirm that consent has been issued and this TFS is ready.</p>
					<p>
						After clicking the <em>Confirm Consent</em> button below, you will be able to upload any relevant consenting
						documents in the next section.
					</p>
				</Alert>
			),
			width: "32rem",
			confirmText: "Confirm Consent",
		})

		if (confirmation) {
			formData.consentIssued = true
		}
	}

	const tfsNoInputRef = shallowRef<HTMLInputElement>()
	watch(toRef(formData, "tfsNumber"), (tfsNo) => (formData.tfsNumber = tfsNo.toUpperCase().replace(/\s/g, "")))
	useInputValidation(tfsNoInputRef, () => {
		if (/^GBG\d+$/i.test(formData.tfsNumber)) {
			return true
		}
		return "Please enter a valid TFS number"
	})

	const wkNoInputRef = shallowRef<HTMLInputElement>()
	watch(toRef(formData, "wkNumber"), (wkNumber) => {
		let newValue = wkNumber.toUpperCase().replace(/\s/g, "")
		if (newValue === "WK") {
			newValue += `/${LocalDate.now().year()}`
		}
		formData.wkNumber = newValue
	})
	useInputValidation(wkNoInputRef, () => {
		if (/^WK\/\d{5,9}$/i.test(formData.wkNumber)) {
			return true
		}
		return "Please enter a valid WK number"
	})

	return () => (
		<>
			<AutoColumns size="14rem" class="spacing-4">
				<label>
					<Required label="TFS Number" />
					<input
						ref={tfsNoInputRef}
						type="text"
						placeholder="GBGxxxx"
						v-model={formData.tfsNumber}
						minlength={4}
						disabled={!canEdit.value || isConsentIssued.value}
						required
					/>
				</label>
				<label>
					<Required label="WK Number" />
					<input
						ref={wkNoInputRef}
						type="text"
						placeholder="WK/YYYYxxxxx"
						v-model={formData.wkNumber}
						minlength={8}
						disabled={!canEdit.value || isConsentIssued.value}
						required
					/>
				</label>
				<label>
					<Required label="Company" />
					<BasicSelect
						options={Array.from(companies.values())}
						value={formData.companyId}
						setValue={(company) => (formData.companyId = company.id)}
						defaultText="Select a company"
						disabled={!canEdit.value || isConsentIssued.value}
						required
					/>
				</label>
				<label>
					Is Consent Issued?
					<ToggleButton
						toggled={!!formData.consentIssued}
						setToggled={(toggled) => void handleConsentIssuedChange(toggled)}
						disabled={!canEdit.value || isConsentIssued.value}
					/>
				</label>
				<label>
					{formData.consentIssued ? <Required label="Valid From" /> : "Valid From"}
					<input
						type="date"
						value={formData.validFrom?.toHTMLInput()}
						onChange={useOnInput((txt) => (formData.validFrom = LocalDate.fromHtmlInput(txt)))}
						max={formData.validTo?.toHTMLInput()}
						disabled={!canEdit.value || isConsentIssued.value}
						required={!!formData.consentIssued}
					/>
				</label>
				<label>
					{formData.consentIssued ? <Required label="Valid To" /> : "Valid To"}
					<input
						type="date"
						value={formData.validTo?.toHTMLInput()}
						onChange={useOnInput((txt) => (formData.validTo = LocalDate.fromHtmlInput(txt)))}
						min={dateToMin.value}
						disabled={!canEdit.value || isConsentIssued.value}
						required={!!formData.consentIssued}
					/>
				</label>
				<label>
					{formData.consentIssued ? <Required label="Number of Shipments" /> : "Number of Shipments"}
					<input
						type="number"
						v-model={formData.maximumShipments}
						min={Math.max(1, formData.maximumActiveShipments ?? 1)}
						max={5000}
						step={1}
						disabled={!canEdit.value}
						required={!!formData.consentIssued}
					/>
				</label>
				<label>
					{formData.consentIssued ? <Required label="Number of Active Shipments" /> : "Number of Active Shipments"}
					<input
						type="number"
						v-model={formData.maximumActiveShipments}
						min={1}
						max={Math.min(1000, formData.maximumShipments ?? 1000)}
						step={1}
						disabled={!canEdit.value}
						required={!!formData.consentIssued}
					/>
				</label>
			</AutoColumns>
			<label class="flex flex-grow-1" style={{ marginTop: "1rem" }}>
				Notes
				<textarea class="flex-grow-1" rows={5} v-model={formData.notes} disabled={!canEdit.value} />
			</label>
		</>
	)
}

export default defineComponent(TFSDetails)
