import { useVueAuth } from "oidc-vue"
import { useRouter } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { StateLoading } from "./components/loading/StateLoading"
import Popups from "./components/Popups"
import MainApp from "./MainApp"
import Login from "./pages/login"
import LoginNotEnabled from "./pages/LoginNotEnabled"
import Profile from "./pages/profile"
import { useLoggedInUserStore } from "./stores/loggedInUserStore"

const App: ReactiveComponent = () => {
	const userStore = useLoggedInUserStore()

	const { loggedIn } = useVueAuth()
	const router = useRouter()

	function renderContent() {
		if (userStore.type !== "done") {
			return null
		}
		const user = userStore.result
		if (user.loginEnabled) {
			return <MainApp />
		}

		//Login not enabled, special case to allow access to log out
		const currentRoute = router.currentRoute.value
		if (currentRoute.path === "/profile") {
			return <Profile />
		}

		return <LoginNotEnabled />
	}

	function renderMain() {
		if (!loggedIn.value) {
			return <Login />
		}
		return <StateLoading stores={[userStore]}>{renderContent()}</StateLoading>
	}

	return () => (
		<>
			{renderMain()}
			<Popups />
		</>
	)
}

export default defineComponent(App)
