import { createRouter, createWebHistory, type RouteComponent, type RouteRecordRaw } from "vue-router"
import CompaniesPage from "./companies"
import HomePage from "./home"
import NotFound from "./NotFound"
import ProfilePage from "./profile"
import ManageShipments from "./shipments"
import TFSEntriesPage from "./tfs-entries"
import UsersPage from "./users"

const simpleRoutes: Record<string, RouteComponent> = {
	"/": HomePage,
	"/companies": CompaniesPage,
	"/tfs-entries": TFSEntriesPage,
	"/tfs-entries/:tfsId": ManageShipments,
	"/users": UsersPage,
	"/profile": ProfilePage,
	"/:pathMatch(.*)*": NotFound,
}
const routes: RouteRecordRaw[] = Object.entries(simpleRoutes).map(([path, component]) => ({
	path,
	component,
	name: component.name,
}))

const router = createRouter({
	routes,
	history: createWebHistory(),
})

export default router
