import BootstrapButton from "@/components/form/BootstrapButton"
import { defineStore } from "pinia"
import {
	ColumnLayout,
	ConfirmResult,
	createPopups,
	renderSlot,
	type AlertPopup,
	type ConfirmPopup,
	type Popup,
} from "vue-utils"

export { ConfirmResult }
export type { AlertPopup, ConfirmPopup, Popup }

const usePopups = defineStore("popups", () => {
	return createPopups({
		AlertPopup: ({ okText, onClose }, { slots }) => (
			<form
				class="flex flex-col"
				onSubmit={(e) => {
					e.preventDefault()
					onClose()
				}}
			>
				{renderSlot(slots)}
				<hr class="w-full" />
				<div class="flex justify-end">
					<BootstrapButton color="secondary" type="submit">
						{okText}
					</BootstrapButton>
				</div>
			</form>
		),
		ConfirmPopup: ({ cancelText, onCancel, confirmText, onSubmit }, { slots }) => (
			<form
				class="flex flex-col"
				onSubmit={(e) => {
					e.preventDefault()
					onSubmit()
				}}
			>
				{renderSlot(slots)}
				<hr class="w-full" />
				<div class="flex justify-end">
					<ColumnLayout spacing="1rem" columns={2}>
						<BootstrapButton color="secondary" type="button" onClick={onCancel}>
							{cancelText}
						</BootstrapButton>
						<BootstrapButton color="primary" type="submit">
							{confirmText}
						</BootstrapButton>
					</ColumnLayout>
				</div>
			</form>
		),
	})
})

export default usePopups
