import { createSelect } from "@/components/form/BasicSelect"
import { createMultiSelect } from "@/components/multi-select"
import { ShipmentStatus, getShipmentStatusColor } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { AutoColumns, defineComponent, type ReactiveComponent } from "vue-utils"
import { useShipmentsStore } from "../store"

const StatusMultiSelect = createMultiSelect<ShipmentStatus>()

const ShipmentsFilter: ReactiveComponent = () => {
	const store = useShipmentsStore()
	const filter = proxyProp(() => store.requestOptions.filter)

	const NullableBooleanSelect = createSelect<boolean | null>()

	return () => (
		<AutoColumns size="12.5rem" class="spacing-4">
			<label>
				Shipment Status
				<StatusMultiSelect
					options={Object.values(ShipmentStatus)}
					selected={filter.statuses}
					setSelected={(statuses) => (filter.statuses = statuses)}
					getKey={(s) => s}
					getText={formatEnum}
					getColor={getShipmentStatusColor}
					entityNames="Statuses Selected"
					noneText="All Statuses"
					allText="All Statuses"
					allowSearch={false}
					someText={(s) => s.join(", ")}
					class="w-full"
				/>
			</label>
			<label>
				Has Recovery At Facility?
				<label>
					<NullableBooleanSelect
						options={[true, false]}
						value={filter.hasRecoveryAtFacility}
						setValue={(value) => (filter.hasRecoveryAtFacility = value)}
						getText={(cc) => (cc ? "Yes" : "No")}
						getValue={String}
						defaultText="Either"
					/>
				</label>
			</label>
			<label>
				Has Additional Documents?
				<label>
					<NullableBooleanSelect
						options={[true, false]}
						value={filter.hasAdditionalDocuments}
						setValue={(value) => (filter.hasAdditionalDocuments = value)}
						getText={(cc) => (cc ? "Yes" : "No")}
						getValue={String}
						defaultText="Either"
					/>
				</label>
			</label>
		</AutoColumns>
	)
}

export default defineComponent(ShipmentsFilter)
