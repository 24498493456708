import Modal from "@/components/Modal"
import type { Nullable, TFSEntry } from "@/models"
import { computed, h, provide } from "vue"
import { ModalTitle, defineComponent, requiredProp, type Component, type ReactiveComponent } from "vue-utils"
import CloseTFSForm from "./close/CloseTFSForm"
import ConsentingDocuments from "./consent-documents/ConsentingDocuments"
import { TFSFormContext, TFSFormContextInjectionKey, TFSFormSection } from "./context"
import TFSMainDetails from "./main-details"
import AdditionalDocuments from "./main-details/AdditionalDocuments"

interface Props {
	existingTFS: Nullable<TFSEntry>
}

const TFSEntryForm: ReactiveComponent<Props> = (props) => {
	const context = new TFSFormContext(props.existingTFS)
	provide(TFSFormContextInjectionKey, context)

	const title = computed(() => {
		if (context.type === "create") return "Create New TFS"
		if (context.canEdit) return "Edit TFS Information"
		return "View TFS Information"
	})

	const sections: Record<TFSFormSection, Component> = {
		[TFSFormSection.Main]: TFSMainDetails,
		[TFSFormSection.ConsentIssued]: ConsentingDocuments,
		[TFSFormSection.AdditionalDocuments]: AdditionalDocuments,
	}

	return () => (
		<>
			<ModalTitle title={title.value} />
			<hr />
			{h(sections[context.section])}
			{context.showingClosePopup && (
				<Modal zIndex={4} style={{ width: "56rem", height: "32rem" }}>
					<CloseTFSForm />
				</Modal>
			)}
		</>
	)
}

export default defineComponent(TFSEntryForm, {
	existingTFS: requiredProp(Object, null),
})
