import type { Id } from "@/models"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import tinycolor from "tinycolor2"
import { css } from "vite-css-in-js"
import type { HTMLAttributes } from "vue"
import { calculateForegroundColor, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"

interface Props {
	options: DropdownListOption[]
	toggleSelected(option: DropdownListOption): void
}
export interface DropdownListOption {
	id: Id | string
	name: string
	selected: boolean
	color?: string
}

const dropdownStyles = css`
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.333);
	border-radius: 0.25rem;
	z-index: 1;
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;

	ul {
		margin: 0;
		list-style: none;
		padding: 0;
		overflow-y: auto;

		max-height: max(8rem, min(28rem, 50vh));
	}

	& > ul > li > button {
		all: unset;
		padding: 0.25rem 0.5rem;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		justify-content: space-between;

		&:not([disabled], .disabled) {
			cursor: pointer;
			&:hover {
				background-color: var(--dropdown-hover-color);
			}
		}
	}
	li {
		display: flex;
		user-select: none;
		padding: 0;

		span {
			width: 0;
			flex-basis: 0;
			flex-grow: 1;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.fa-check {
		display: flex;
		color: #2b994c;
	}
`

const DropdownList: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	function renderOption(option: DropdownListOption, keyPrefix = "") {
		const backgroundColor = option.color ?? "#ffffff"
		const hoverColor = tinycolor(backgroundColor).darken(20).desaturate(25).toHexString()
		const foregroundColor = calculateForegroundColor(backgroundColor)

		return (
			<li
				key={`${keyPrefix}${option.id}`}
				title={option.name}
				tabindex={-1}
				style={{
					backgroundColor,
					color: foregroundColor,
					"--dropdown-hover-color": hoverColor,
				}}
			>
				<button
					tabindex={-1}
					onClick={(e) => {
						e.preventDefault()
						props.toggleSelected(option)
					}}
					onMousedown={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}
				>
					<span>{option.name}</span>
					{option.selected && <Icon icon={faCheck} />}
				</button>
			</li>
		)
	}

	return () => (
		<div class={dropdownStyles} tabindex={-1} {...attrs}>
			<ul>
				{props.options.map((option) => renderOption(option))}
				{props.options.length === 0 && (
					<li tabindex={-1}>
						<button
							class="disabled"
							tabindex={-1}
							onMousedown={(e) => {
								e.preventDefault()
								e.stopPropagation()
							}}
						>
							<i>No options were found</i>
						</button>
					</li>
				)}
			</ul>
		</div>
	)
}

export default defineComponent(DropdownList, {
	options: requiredProp(Array),
	toggleSelected: requiredProp(Function),
})
