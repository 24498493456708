import logo from "@/assets/logo.svg"
import { useCompaniesStore } from "@/stores/companiesStore"
import { useLoggedInUserStore } from "@/stores/loggedInUserStore"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import ProfileButton from "./ProfileButton"

const headerStyles = css`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	letter-spacing: 0.0125rem;
	background-color: white;
	height: 3rem;
	font-weight: 500;
	box-shadow: 0 0.25rem 0.25rem #0000001f;
	z-index: 2;
	font-size: 1.25rem;
	border-bottom: thin solid rgba(0, 0, 0, 0.2);

	img {
		height: 2.35rem;
		width: auto;
		object-fit: contain;
	}
`

interface Props {
	title: string
	showLogo?: boolean
}

const AppHeader: ReactiveComponent<Props> = (props) => {
	const companiesStore = useCompaniesStore()
	const loggedInUserStore = useLoggedInUserStore()

	const associatedCompany = computed(() => {
		if (companiesStore.type !== "done" || loggedInUserStore.type !== "done") {
			return null
		}
		const user = loggedInUserStore.result
		if (!user) {
			return null
		}
		if (user.companyId === null) {
			return null
		}
		return companiesStore.result.get(user.companyId) ?? null
	})
	const loggedIn = computed(() => loggedInUserStore.type === "done" && !!loggedInUserStore.result)

	return () => (
		<header class={headerStyles} style={{ paddingLeft: props.showLogo ? "0.5rem" : "1.5rem" }}>
			{props.showLogo && <img src={logo} alt="Logo" />}
			<span class="flex-grow-1" style={{ whiteSpace: "nowrap" }}>
				{props.title}
			</span>
			{!!associatedCompany.value && <span style={{ fontSize: "1.2rem" }}>{associatedCompany.value.name}</span>}
			{loggedIn.value && <ProfileButton />}
		</header>
	)
}

export default defineComponent(AppHeader, {
	title: requiredProp(String),
	showLogo: optionalProp(Boolean),
})
