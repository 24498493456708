import { LoggedInUser, SystemRole } from "@/models"
import { faBuilding, faShip, faUser, type IconDefinition } from "@fortawesome/free-solid-svg-icons"

export interface RouteInformation {
	name: string
	icon: IconDefinition
	hasAccess?(user: LoggedInUser): boolean
}

export const WasteShippingRoutes: Record<string, RouteInformation> = {
	"/tfs-entries": {
		name: "Transfrontier Shipments",
		icon: faShip,
	},
	"/companies": {
		name: "Manage Companies",
		icon: faBuilding,
		hasAccess: (user) => user.atLeastHasRole(SystemRole.Administrator),
	},
	"/users": {
		name: "Manage Users",
		icon: faUser,
		hasAccess: (user) => user.atLeastHasRole(SystemRole.Administrator),
	},
}
