import TableControls from "@/components/table/TableControls"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useTFSEntriesStore } from "./store"
import TFSTable from "./table/TFSTable"

const TFSResultsDisplay: ReactiveComponent = () => {
	const store = useTFSEntriesStore()

	return () => {
		const result = store.getResults()
		if (result.globalTotal === 0) {
			return <p>There are no TFS entries recorded in the system.</p>
		}
		if (result.totalItems === 0) {
			return <p>No entries match your selected criteria.</p>
		}
		return (
			<>
				<TFSTable />
				<TableControls
					entityName="TFS Entries"
					page={store.requestOptions.page}
					pageSize={store.requestOptions.pageSize}
					totalItems={result.totalItems}
					totalPages={result.totalPages}
					setPage={(page) => (store.requestOptions.page = page)}
					setPageSize={(pageSize) => (store.requestOptions.pageSize = pageSize)}
				/>
			</>
		)
	}
}

export default defineComponent(TFSResultsDisplay)
