import SortableHeading from "@/components/table/SortableHeading"
import type { TdHTMLAttributes } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import type { TFSSearchSorting } from "../requests"
import { useTFSEntriesStore } from "../store"

interface Props {
	sort: TFSSearchSorting
	text: string
}

const SortableTFSHeading: ReactiveComponent<Props, TdHTMLAttributes> = (props, { attrs }) => {
	const store = useTFSEntriesStore()

	return () => (
		<SortableHeading
			direction={store.requestOptions.sortBy === props.sort ? store.requestOptions.sortDirection : null}
			setDirection={(dir) => {
				store.requestOptions.sortBy = props.sort
				store.requestOptions.sortDirection = dir
			}}
			align="center"
			{...attrs}
		>
			{props.text}
		</SortableHeading>
	)
}

export default defineComponent(SortableTFSHeading, {
	text: requiredProp(String),
	sort: requiredProp(String),
})
