import type { Company, Id } from "@/models"
import * as service from "@/services/companiesService"
import { arrayToMap } from "@/utils/arrayToMap"
import { toReactive } from "@vueuse/core"
import { defineStore } from "pinia"
import { toRef } from "vue"
import { ensureLoadingHasResult, piniaLoadableState } from "vue-utils"

async function loadCompanies() {
	const companies = await service.getCompanies()
	return arrayToMap(companies)
}

export const useCompaniesStore = defineStore("companies", {
	state: () => piniaLoadableState(loadCompanies),
	actions: {
		getCompanies(): Map<Id, Company> {
			return ensureLoadingHasResult(this)
		},
		async createCompany(company: Company) {
			const newCompany = await service.createCompany(company)
			this.getCompanies().set(newCompany.id, newCompany)
		},

		async updateCompany(company: Company) {
			const updatedCompany = await service.updateCompany(company)
			this.getCompanies().set(updatedCompany.id, updatedCompany)
		},
		async deleteCompany(company: Id | Company) {
			const id = typeof company === "number" ? company : company.id
			await service.deleteCompany(id)
			this.getCompanies().delete(id)
		},
	},
})

export function useCompanies(): Map<Id, Company> {
	const store = useCompaniesStore()
	return toReactive(toRef(() => store.getCompanies()))
}
