import Modal from "@/components/Modal"
import BootstrapButton from "@/components/form/BootstrapButton"
import type { DocumentUpload, ShipmentLogDocumentKeys } from "@/models"
import ExistingDocuments from "@/shared/ExistingDocuments"
import { ref } from "vue"
import { defineComponent, ModalTitle, type ReactiveComponent, requiredProp } from "vue-utils"
import UploadDocumentsForm from "./UploadDocumentsForm"
import Icon from "@/components/Icon"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

interface Props {
	title: string
	existingDocuments: DocumentUpload[]
	canUpload: boolean
	type: ShipmentLogDocumentKeys

	uploadDocuments(file: File[]): Promise<void>
	close(): void
}

const ManageDocuments: ReactiveComponent<Props> = (props) => {
	const uploadingDocs = ref(false)

	return () => (
		<Modal class="flex flex-col" style={{ width: "60rem", height: "36rem" }}>
			<ModalTitle title={props.title} />
			<hr />

			<div class="flex flex-col flex-grow-1 flex-shrink-1 overflow-auto">
				{props.existingDocuments.length === 0 && <ExistingDocuments name="" documents={props.existingDocuments} />}
				{uploadingDocs.value && props.canUpload && (
					<Modal zIndex={4} style={{ width: "56rem", height: "32rem" }}>
						<UploadDocumentsForm
							type={props.type}
							hasExisting={props.existingDocuments.length > 0}
							uploadDocuments={props.uploadDocuments}
							cancel={() => (uploadingDocs.value = false)}
						/>
					</Modal>
				)}
				{props.canUpload && (
					<div class={props.existingDocuments.length === 0 ? "mt-2" : "mb-2"}>
						<BootstrapButton color="success" onClick={() => (uploadingDocs.value = true)}>
							<Icon icon={faPlusCircle} />
							Upload Documents
						</BootstrapButton>
					</div>
				)}
				{props.existingDocuments.length > 0 && <ExistingDocuments name="" documents={props.existingDocuments} />}
			</div>
			<hr />

			<div class="flex justify-end spacing-3">
				<BootstrapButton color="secondary" onClick={props.close}>
					Close
				</BootstrapButton>
			</div>
		</Modal>
	)
}

export default defineComponent(ManageDocuments, {
	title: requiredProp(String),
	existingDocuments: requiredProp(Array),
	canUpload: requiredProp(Boolean),
	type: requiredProp(String),

	uploadDocuments: requiredProp(Function),
	close: requiredProp(Function),
})
