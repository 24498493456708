import type { FormButton } from "@/components/form/BasicForm"
import BasicForm from "@/components/form/BasicForm"
import BootstrapButton from "@/components/form/BootstrapButton"
import type { Company } from "@/models"
import usePopups from "@/stores/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import { computed } from "vue"
import {
	Alert,
	AutoColumns,
	Required,
	defineComponent,
	optionalProp,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	company: Company
	type: "create" | "edit"

	cancel(): void
	save(): Promise<void>
	delete?(): Promise<void>
}

const CompanyForm: ReactiveComponent<Props> = (props) => {
	const company = proxyProp(props, "company")
	const popups = usePopups()

	async function confirmDelete() {
		return await popups.confirm(() => (
			<Alert title="Confirm delete company?">
				Are you sure you wish to delete this company's information from the system?
			</Alert>
		))
	}

	const buttons = computed(() => {
		const cancelButton: FormButton = {
			id: "cancel",
			onSubmit: props.cancel,
			validateForm: false,
			render: (btnProps) => (
				<BootstrapButton color="secondary" {...btnProps}>
					Cancel
				</BootstrapButton>
			),
		}
		const submitButton: FormButton = {
			id: "save",
			onSubmit: props.save,
			validateForm: true,
			render: (btnProps) => (
				<BootstrapButton color="primary" {...btnProps}>
					{props.type === "create" ? "Create" : "Save"}
				</BootstrapButton>
			),
		}
		const deleteButton: FormButton = {
			id: "delete",
			validate: confirmDelete,
			validateForm: false,
			onSubmit: () => props.delete?.(),
			render: (btnProps) => (
				<BootstrapButton color="danger" {...btnProps}>
					Delete
				</BootstrapButton>
			),
		}
		return props.type === "create" ? [cancelButton, submitButton] : [deleteButton, cancelButton, submitButton]
	})

	return () => (
		<BasicForm buttons={buttons.value}>
			<div class="flex flex-col spacing-4">
				<label>
					<Required label="Company Name" />
					<input type="text" placeholder="Enter company name..." v-model={company.name} required />
				</label>
				<AutoColumns size="16rem" class="spacing-4">
					<label>
						<Required label="Main Contact" />
						<input type="text" placeholder="Enter main contact's name..." v-model={company.mainContact} required />
					</label>
					<label>
						<Required label="Email" />
						<input type="email" placeholder="Enter main contact's email..." v-model={company.email} required />
					</label>
					<label>
						<Required label="Telephone Number" />
						<input type="tel" placeholder="Enter main contact's email..." v-model={company.telephoneNumber} required />
					</label>
				</AutoColumns>
				<label>
					<Required label="Postcode" />
					<input type="text" v-model={company.postcode} placeholder="Enter the company's postcode..." required />
				</label>
				<label>
					<Required label="Address" />
					<textarea rows={4} v-model={company.address} placeholder="Enter the company's address..." required />
				</label>
			</div>
		</BasicForm>
	)
}

export default defineComponent(CompanyForm, {
	company: requiredProp(Object),
	type: requiredProp(String),

	cancel: requiredProp(Function),
	save: requiredProp(Function),
	delete: optionalProp(Function),
})
