import usePopups from "@/stores/popupsStore"
import { Alert, formatFileSize } from "vue-utils"

export interface UploadValidationOptions {
	allowNoFiles: boolean
	maxUploadSize?: number | null
	allowedMimeTypes?: string[] | null
}

export class UploadValidation {
	static readonly MaxUploadSize = 1024 * 1024 * 100 //100MB
	static readonly AllowedMimeTypes = [
		"application/pdf",
		"application/msword",
		"application/vnd.oasis.opendocument.text",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"image/jpeg",
		"image/png",
		"image/webp",
		"text/csv",
		"text/plain",
		"message/rfc822",
	]

	private popups
	readonly files: Readonly<File[]>

	private allowNoFiles
	private maxUploadSize
	private allowedMimeTypes

	constructor(files: File[], options: UploadValidationOptions) {
		this.popups = usePopups()
		this.files = files

		const {
			allowNoFiles,
			maxUploadSize = UploadValidation.MaxUploadSize,
			allowedMimeTypes = UploadValidation.AllowedMimeTypes,
		} = options

		this.allowNoFiles = allowNoFiles
		this.maxUploadSize = maxUploadSize
		this.allowedMimeTypes = allowedMimeTypes
	}

	static validateFileUpload(files: File[], options: UploadValidationOptions): boolean {
		const validation = new UploadValidation(files, options)
		return validation.validateFileUpload()
	}

	validateFileUpload(): boolean {
		return this.validateFileCount() && this.validateFileSize() && this.validateMimeTypes()
	}

	private validateFileCount(): boolean {
		if (this.files.length > 0 || this.allowNoFiles) {
			return true
		}
		void this.popups.showAlertPopup(() => (
			<Alert title="Please Upload a File">
				<p>Please upload at least 1 file</p>
			</Alert>
		))
		return false
	}

	private validateFileSize(): boolean {
		const maxUploadSize = this.maxUploadSize
		if (!maxUploadSize || maxUploadSize <= 0 || !this.files.length) {
			return true
		}
		const tooLargeFiles = this.files.filter((file) => file.size > maxUploadSize)
		if (tooLargeFiles.length > 0) {
			void this.popups.showAlertPopup(() => (
				<Alert title="Files too Large">
					<p>The following files are too large:</p>
					<ul>
						{tooLargeFiles.map((file, i) => (
							<li key={i}>{file.name}</li>
						))}
					</ul>
					<p>Please ensure these files are less than the limit of {formatFileSize(maxUploadSize)}</p>
				</Alert>
			))
			return false
		}
		const total = this.files.map((f) => f.size).reduce((a, b) => a + b, 0)
		if (total > maxUploadSize) {
			void this.popups.showAlertPopup(() => (
				<Alert title="Files too Large">
					<p>
						Please ensure the total file size of all files your are uploading is less than{" "}
						{formatFileSize(maxUploadSize)}
						.
						<br />
						You are currently trying to upload {formatFileSize(total)} of files.
					</p>
				</Alert>
			))
			return false
		}
		return true
	}

	private validateMimeTypes(): boolean {
		if (!this.allowedMimeTypes || !this.allowedMimeTypes.length) {
			return true
		}
		const allowedLower = new Set<string>()
		for (const type of this.allowedMimeTypes) {
			allowedLower.add(type.trim().toLowerCase())
		}
		const invalidFiles = this.files.filter((f) => !allowedLower.has(f.type.toLowerCase()))
		if (invalidFiles.length === 0) {
			return true
		}

		void this.popups.showAlertPopup(() => (
			<Alert title="Invalid files uploaded">
				<p>The following files are not allowed to be uploaded:</p>
				<ul>
					{invalidFiles.map((file, i) => (
						<li key={i}>{file.name}</li>
					))}
				</ul>
				<p>Please remove these files and try again.</p>
			</Alert>
		))
		return false
	}
}
